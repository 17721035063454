import Navigation from "./Navigation";
import { logout } from "../../services/AuthService";
import { Link,useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContext } from "../../utils/AppProvider";
import { useContext } from "react";

const Dashboard = () => {

  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AppContext);

  const handleLogout = async (e) => {
    e.preventDefault();
    await logout().then(() => {
       localStorage.removeItem('is_authenticated');
       localStorage.removeItem('token');
       setIsAuthenticated(0);

       navigate('/');
    }).catch((error) => {
       if (error.response.status == 422)
          toast(error.response.data.error, { type: 'error' })
       else if (error.response.status == 500)
          toast(error.response.data.message, { type: 'error' })
       else
          toast("Something went wrong", { type: 'error' });
    })

 }
    return (
        <main id="main" className="site-primary">
        <div className="site-content">
          <div className="homepage-content">
            <div className="shop-content my-account-page">
              <div className="container">
                <nav className="woocommerce-breadcrumb"><ul><li><a href="#https://klbtheme.com/bacola">Home</a></li><li>My account</li></ul></nav>					
                <div className="woocommerce"><div className="row content-wrapper sidebar-right">
                    <div className="col-12 col-md-12 col-lg-12 content-primary">
                      <div className="my-account-wrapper"> {/* my-account-wrapper be closed in myaccount.php */}
                        <div className="my-account-navigation"> 
                          <div className="account-toggle-menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"><line x1="2.3" y1={12} x2="21.8" y2={12} /><line x1="2.3" y1={6} x2="21.8" y2={6} /><line x1="2.3" y1={18} x2="21.8" y2={18} /></svg>
                            Navigation				
                            </div>{/* account-toggle-menu */}
                          <Navigation />
                        </div>
                        <div className="woocommerce-MyAccount-content">
                          <div className="woocommerce-notices-wrapper" />
                          <p>
                            Hello <strong>vebaqyh</strong> (not <strong>vebaqyh</strong>? <Link onClick={(e) => handleLogout(e)}>Log out</Link>)</p>
                          <p>
                            From your account dashboard you can view your <Link to="/my-account/orders">recent orders</Link>, manage your <Link to="/my-account/addresses">shipping and billing addresses</Link>, and <Link to="/my-account/account-details">edit your password and account details</Link>.</p>
                        </div>
                      </div> {/* my-account-wrapper closed in navigation.php */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{/* homepage-content */}
        </div>{/* site-content */}
      </main>
    );
}

export default Dashboard;