import { useContext, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { retriveBrands, retriveSingleCategory } from "../../services/CategoryService";
import { addItemToCart, retriveItems } from "../../services/ItemService";
import { Link } from "react-router-dom";
import RecentViewProducts from "../../common/RecentViewProducts";
import { toast } from "react-toastify";
import { isLogin } from "../../utils/constant";
import { AppContext } from "../../utils/AppProvider";
import { useRef } from "react";

const ProductCategory = () => {

    const [category, setCategory] = useState([]);
    const [items, setItems] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(12);
    const params = useParams();
    const [wishlistItems, setwishlistItems] = useState([]);
    const [brands, setBrands] = useState([]);
    const [priceSort, setPriceSort] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const {setCartQty, cart_qty} = useContext(AppContext);
    const [showAllBrands, setShowAllBrands] = useState();
    const brandRef = useRef()

    useEffect(() => {
        if (params.category) {
            setPage(1); 
            setPerPage(12);
            getSubcategories(params.category);

            let brands = '';
            if (selectedBrands.length > 0) {
                brands = selectedBrands.join(",");
            }
            getItems(params.category, brands);
        }
        document.getElementById("all-categories").classList.remove("show")

        return () => {
            setSelectedBrands([]);
            setPriceSort('');
        }
    }, [params.category]);

    useEffect(()=>{
        if(!showAllBrands)  brandRef.current.scrollTop = 0;
    },[showAllBrands]);

    useEffect(() => {
        let brands = '';
        if (selectedBrands.length > 0) {
            brands = selectedBrands.join(",");
        }
        getItems(params.category,brands);
    }, [perPage, page]);

    useEffect(() => {
        getWishlist();
        getBrands();
    }, []);

    const getBrands = async () => {
        await retriveBrands().then((response) => {
            if (response.data) {
                setBrands(response.data.data);
            }
        }).catch((error) => {
            console.log("Error", error.response)
        });
    }

    const getSubcategories = async (sub_cat) => {
        await retriveSingleCategory(sub_cat).then((response) => {
            if (response.data.data) {
                setCategory(response.data.data);
            }
        }).catch((error) => {
            console.log("Error", error.response)
        });
    }

    const getItems = async (category,brands) => {
        let params = {
            category: category,
            brands: brands,
            max_price: maxPrice,
            min_price: minPrice,
            per_page: perPage,
            page: page
        }
        await retriveItems(params).then((response) => {
            if (response.data.data) {
                setItems(response.data.data);
            }
        }).catch((error) => {
            console.log("Error", error.response)
        });
    }

    const handleCategoryChange = (e) => {
        let { name, value } = e.target;

        if (selectedCategories.includes(value)) {
            setSelectedCategories(selectedCategories.filter(cat => cat != value));
        } else {
            setSelectedCategories([...selectedCategories, value]);
        }
    }
    
    const handleBrandChange = (brand_id) => {
        let value= brand_id;

        if (selectedBrands.includes(value)) {
            setSelectedBrands(selectedBrands.filter(cat => cat != value));
        } else {
            setSelectedBrands([...selectedBrands, value]);
        }
    }

    useEffect(()=>{
        // asceding Order
        if(priceSort == 'price'){
            const sorted_items = [...items].sort((a, b) => a.purchase_price - b.purchase_price);
            setItems(sorted_items);
        }
        
        // Desceding Order
        if(priceSort == 'price-desc'){
            const sorted_items = [...items].sort((a, b) => b.purchase_price - a.purchase_price);
            setItems(sorted_items);
        }
    },[priceSort]);

    useEffect(() => {
        let categories = params.category;
        if (selectedCategories.length > 0) {
            categories = selectedCategories.join(",");
        }
        let brands = '';
        if (selectedBrands.length > 0) {
            brands = selectedBrands.join(",");
        }

        getItems(categories, brands);
    }, [selectedCategories, selectedBrands])

    const getWishlist = async () => {
        let items = await localStorage.getItem('wishlist_items')

        if (items != null) {
            items = JSON.parse(items);
            let wishlist_item_ids = items.map((_item) => { return _item.id })
            setwishlistItems(wishlist_item_ids);
        }
    }

    const toggleWishlist = async (item) => {
        let items = await localStorage.getItem('wishlist_items')

        let date = new Date();
        date = date.toLocaleString('default', { month: 'long' }) + " " + date.getDate() + ", " + date.getFullYear();

        if (items != null) {
            items = JSON.parse(items);
            let ids = items.map(item => item['id']);

            if (ids.includes(item.id)) {
                let wishlist_items = items.filter((_item) => _item.id != item.id)
                let wishlist_item_ids = wishlist_items.map((_item) => { return _item.id })

                setwishlistItems(wishlist_item_ids);
                localStorage.setItem('wishlist_items', JSON.stringify(wishlist_items));
                toast("Item Removed from Wishlist", { type: 'success' });
            } else {
                item    ['wishlist_added_date'] = date;
                items.push(item)
                setwishlistItems([...wishlistItems, item.id])
                localStorage.setItem('wishlist_items', JSON.stringify(items));
                toast("Item added into Wishlist", { type: 'success' });
            }
        } else {
            let temp = [];
            item['wishlist_added_date'] = date;
            temp.push(item)
            setwishlistItems([item.id])
            localStorage.setItem('wishlist_items', JSON.stringify(temp));

            toast("Item added into Wishlist", { type: 'success' });
        }
    }

    const handleAddToCart = (item) => {
        if (isLogin()) {
            let item_id = item.id;
            addItemToCart(item_id)
                .then((response) => {
                    setCartQty(parseInt(cart_qty) + 1);
                    toast('Item Added To Cart Successfully.', { type: 'success' })
                })
                .catch((error) => {
                    console.log("Error", error);
                    if (error.response.status == 422)
                        toast(error.response.data.message, { type: 'error' })
                    else if (error.response.status == 500)
                        toast(error.response.data.message, { type: 'error' })
                    else
                        toast("Something went wrong", { type: 'error' });
                })
        }else{
            toast('Please login to add item to cart',{type:'error'});
         }
    }

    const handlePriceFilter = () =>{
       /*  if(minPrice <= 0){
            toast('Invalid minimum price in filter',{type:'error'});
            return false;
        }
        if(minPrice > maxPrice){
            toast('Maximum price should be greater than or equal to minimum price.',{type:'error'});
            return false;
        } */
        let categories = params.category;
        if (selectedCategories.length > 0) {
            categories = selectedCategories.join(",");
        }
        let brands = '';
        if (selectedBrands.length > 0) {
            brands = selectedBrands.join(",");
        }
        getItems(categories, brands);
    }
    
    return (
        <main id="main" className="site-primary">
            <div className="site-content">
                <div className="homepage-content">
                    <div className="container">

                        <nav className="woocommerce-breadcrumb">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Shop</li>
                            </ul>
                        </nav>


                        <div className="row content-wrapper sidebar-left">
                            <div className="col-12 col-md-12 col-lg-9 content-primary">

                                <div className="shop-banner">
                                    <div className="module-banner image align-center align-middle">
                                        <div className="module-body">
                                            <div className="banner-wrapper">
                                                <div className="banner-content">
                                                    <div className="content-main">
                                                        <h4 className="entry-subtitle color-text xlight">Organic Meals
                                                            Prepared</h4>
                                                        <h3 className="entry-title color-text large">Delivered to <strong
                                                            className="color-success">your Home</strong></h3>
                                                        <div className="entry-text color-info-dark">Fully prepared &amp;
                                                            delivered nationwide.</div>
                                                    </div>
                                                </div>
                                                <div className="banner-thumbnail">
                                                    <img src="/images/bacola-banner-18.jpg"
                                                        alt="Organic Meals Prepared" />
                                                </div>
                                                <a href="#/shop/?add-to-cart=430"
                                                    className="overlay-link"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="woocommerce-notices-wrapper">
                                    <div className="woocommerce-message" role="alert">
                                        <a href="#/cart/" tabIndex="1"
                                            className="button wc-forward">View cart</a> “All Natural Italian-Style Chicken
                                        Meatballs” has been added to your cart.
                                    </div>
                                </div> */}
                                <div className="before-shop-loop">
                                    {/* <div className="shop-view-selector">

                                        <a href="#/shop/?add-to-cart=430&amp;shop_view=list_view"
                                            className="shop-view">
                                            <i className="klbth-icon-list-grid"></i>
                                        </a>
                                        <a href="#/shop/?add-to-cart=430&amp;column=2&amp;shop_view=grid_view"
                                            className="shop-view">
                                            <i className="klbth-icon-2-grid"></i>
                                        </a>
                                        <a href="#/shop/?add-to-cart=430&amp;column=3&amp;shop_view=grid_view"
                                            className="shop-view">
                                            <i className="klbth-icon-3-grid"></i>
                                        </a>
                                        <a href="#/shop/?add-to-cart=430&amp;column=4&amp;shop_view=grid_view"
                                            className="shop-view active">
                                            <i className="klbth-icon-4-grid"></i>
                                        </a>

                                    </div> */}

                                    <div className="mobile-filter">
                                        <a href="#/shop/?add-to-cart=430#"
                                            className="filter-toggle">
                                            <i className="klbth-icon-filter"></i>
                                            <span>Filter Products</span>
                                        </a>
                                    </div>

                                    <form className="woocommerce-ordering product-filter" method="get">
                                        <span className="orderby-label hide-desktop">Sort by</span>
                                        <select value={priceSort} onChange={(e) =>  setPriceSort(e.target.value)} name="orderby" className="orderby filterSelect"
                                            aria-label="Shop order"
                                            data-select2-id="1" tabIndex="-1" aria-hidden="true">

                                            <option value="">Sort By Price</option>
                                            <option value="price">Sort by price: low to high</option>
                                            <option value="price-desc">Sort by price: high to low</option>
                                        </select>
                                        {/* <span className="select2 select2-container select2-container--default"
                                            dir="ltr" data-select2-id="2" style={{width: '215.859px'}}><span
                                                className="selection"><span
                                                    className="select2-selection select2-selection--single" role="combobox"
                                                    aria-haspopup="true" aria-expanded="false" tabIndex="0"
                                                    aria-disabled="false"
                                                    aria-labelledby="select2-orderby-i8-container"><span
                                                        className="select2-selection__rendered"
                                                        id="select2-orderby-i8-container" role="textbox"
                                                        aria-readonly="true" title="Sort by latest">Sort by
                                                        latest</span><span className="select2-selection__arrow"
                                                        role="presentation"><b
                                                            role="presentation"></b></span></span></span><span
                                                className="dropdown-wrapper" aria-hidden="true"></span></span> */}
                                        <input onChange={() => { }} type="hidden" name="paged" value="1" />
                                        <input onChange={() => { }} type="hidden" name="add-to-cart" value="430" />
                                    </form>

                                    <form className="products-per-page product-filter" method="get">
                                        <span className="perpage-label">Show</span>
                                        <select value={perPage} onChange={(e) => { setPerPage(e.target.value); setPage(1) }} name="perpage" className="perpage filterSelect"
                                            data-select2-id="4" tabIndex="-1"
                                            aria-hidden="true">
                                            <option value="12" data-select2-id="6">12</option>
                                            <option value="24">24</option>
                                            <option value="36">36</option>
                                            <option value="48">48</option>

                                        </select>
                                        {/* <span className="select2 select2-container select2-container--default"
                                            dir="ltr" data-select2-id="5" style={{width: '51.1458px'}}><span
                                                className="selection"><span
                                                    className="select2-selection select2-selection--single" role="combobox"
                                                    aria-haspopup="true" aria-expanded="false" tabIndex="0"
                                                    aria-disabled="false"
                                                    aria-labelledby="select2-perpage-wm-container"><span
                                                        className="select2-selection__rendered"
                                                        id="select2-perpage-wm-container" role="textbox"
                                                        aria-readonly="true" title="12">12</span><span
                                                        className="select2-selection__arrow" role="presentation"><b
                                                            role="presentation"></b></span></span></span><span
                                                className="dropdown-wrapper" aria-hidden="true"></span></span> */}
                                        <input onChange={() => { }} type="hidden" name="add-to-cart" value="430" />
                                    </form>
                                </div>



                                <div className="products column-4 mobile-column-2 align-inherit">
                                    {
                                        items.map((item, index) => {
                                            return (
                                                <div key={index}
                                                    className="product type-product post-430 status-publish first instock product_cat-meats-seafood product_tag-chicken product_tag-natural product_tag-organic has-post-thumbnail sale shipping-taxable purchasable product-type-simple">
                                                    <div className="product-wrapper product-type-1">
                                                        <div className="thumbnail-wrapper">
                                                            <div className="product-badges"><span
                                                                className="badge style-1 onsale">23%</span><span
                                                                    className="badge style-1 recommend">recommended</span></div>
                                                            <a
                                                                href={"/product/" + item.id + "/" + item.item_name}><img
                                                                    src={item?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.image : process.env.REACT_APP_BASE_URL + "/storage/products/product-image-62-346x310.jpg"}
                                                                    alt={item?.item_name} /></a>
                                                            <div className="product-buttons"><a
                                                                href="#/shop/430"
                                                                className="detail-bnt quick-view-button"><svg
                                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                    <path
                                                                        d="M128 32V0H16C7.163 0 0 7.163 0 16v112h32V54.56L180.64 203.2l22.56-22.56L54.56 32H128zM496 0H384v32h73.44L308.8 180.64l22.56 22.56L480 54.56V128h32V16c0-8.837-7.163-16-16-16zM480 457.44L331.36 308.8l-22.56 22.56L457.44 480H384v32h112c8.837 0 16-7.163 16-16V384h-32v73.44zM180.64 308.64L32 457.44V384H0v112c0 8.837 7.163 16 16 16h112v-32H54.56L203.2 331.36l-22.56-22.72z">
                                                                    </path>
                                                                </svg></a>
                                                                <div className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart" onClick={() => toggleWishlist(item)}
                                                                    data-tinvwl_product_id="430">
                                                                    <div className="tinv-wishlist-clear"></div><a role="button"
                                                                        tabIndex="0" aria-label="Add to Wishlist"
                                                                        className={"tinvwl_add_to_wishlist_button tinvwl-icon-heart  tinvwl-position-shortcode" + (wishlistItems.includes(item.id) ? ' tinvwl-product-in-list' : '')}
                                                                        data-tinv-wl-list="[]" data-tinv-wl-product="430"
                                                                        data-tinv-wl-productvariation="0"
                                                                        data-tinv-wl-productvariations="[0]"
                                                                        data-tinv-wl-producttype="simple"
                                                                        data-tinv-wl-action="add"><span
                                                                            className="tinvwl_add_to_wishlist-text">Add to
                                                                            Wishlist</span></a>
                                                                    <div className="tinv-wishlist-clear"></div>
                                                                    <div className="tinvwl-tooltip">Add to Wishlist</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="content-wrapper">
                                                            <h3 className="product-title"><a
                                                                href={"/product/" + item.id + "/" + item.item_name}
                                                                title="All Natural Italian-Style Chicken Meatballs"> {item.item_name}</a></h3>
                                                            <div className="product-meta">
                                                                {item.stock > 0 ?
                                                                    <div className="product-available in-stock"> In Stock</div>
                                                                    : <div className="product-available out-of-stock"> Out Of Stock</div>
                                                                }
                                                            </div>
                                                            <div className="product-rating">
                                                                <div className="star-rating" role="img"
                                                                    aria-label="Rated 4.00 out of 5"><span style={{ width: '80%' }}>Rated
                                                                        <strong className="rating">4.00</strong> out of 5</span></div>
                                                                <div className="count-rating">1 <span className="rating-text">Ratings</span>
                                                                </div>
                                                            </div><span className="price"><del aria-hidden="true"><span
                                                                className="woocommerce-Price-amount amount"><bdi><span
                                                                    className="woocommerce-Price-currencySymbol">$</span>{item.sales_price}</bdi></span></del>
                                                                <ins><span className="woocommerce-Price-amount amount"><bdi><span
                                                                    className="woocommerce-Price-currencySymbol">$</span>{item.purchase_price}</bdi></span></ins></span>
                                                            <div className="product-fade-block">
                                                                <div
                                                                    className="product-button-group cart-with-quantity product-in-cart">
                                                                    {/* <div className="quantity ajax-quantity">
                                                            <div className="quantity-button minus"><i
                                                                    className="klbth-icon-minus"></i></div><input onChange={()=>{}}
                                                                type="text" className="input-text qty text" name="quantity"
                                                                step="1" min="" max="16" value="1" title="Menge"
                                                                size="4" inputMode="numeric" />
                                                            <div className="quantity-button plus"><i
                                                                    className="klbth-icon-plus"></i></div>
                                                        </div> */}
                                                                    <a
                                                                        href=""
                                                                        onClick={(e) => { e.preventDefault(); handleAddToCart(item) }}
                                                                        data-quantity="1"
                                                                        className="button-primary xsmall rounded wide button product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                        data-product_id="430" data-product_sku="ZU49VOR"
                                                                        rel="nofollow">Add to cart</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product-content-fade border-info"
                                                        style={{ marginBottom: '-48.9974px' }}></div>
                                                    {/* <button
                                            className="woosc-btn woosc-btn-430 " data-id="430"
                                            data-product_name="All Natural Italian-Style Chicken Meatballs"
                                            data-product_image="#/wp-content/uploads/2021/04/product-image-62-150x150.jpg">Compare</button> */}

                                                    {/* <div className="tinv-wraper woocommerce tinv-wishlist tinvwl-after-add-to-cart tinvwl-loop-button-wrapper"
                                            data-tinvwl_product_id="430">
                                            <div className="tinv-wishlist-clear"></div><a role="button" tabIndex="0"
                                                aria-label="Add to Wishlist"
                                                className="tinvwl_add_to_wishlist_button tinvwl-icon-heart  tinvwl-position-after tinvwl-loop"
                                                data-tinv-wl-list="[]" data-tinv-wl-product="430"
                                                data-tinv-wl-productvariation="0" data-tinv-wl-productvariations="[0]"
                                                data-tinv-wl-producttype="simple" data-tinv-wl-action="add"><span
                                                    className="tinvwl_add_to_wishlist-text">Add to Wishlist</span></a>
                                            <div className="tinv-wishlist-clear"></div>
                                            <div className="tinvwl-tooltip">Add to Wishlist</div>
                                        </div> */}
                                                </div>
                                            )
                                        })
                                    }


                                </div>
                                {
                                    items.length > 0 ?

                                        <nav className="woocommerce-pagination">
                                            <ul className="page-numbers product-cat-pagination">
                                                {/* <li><span aria-current="page" className="page-numbers current">1</span></li> */}
                                                <li><a href="javascript:void(0)" className={"page-numbers" + (page == 1 ? ' padination-disabled' : '')} onClick={() => setPage(page - 1)}> &#60;&#60; Previous </a></li>
                                                <li><a href="javascript:void(0)" className="page-numbers current">{page}</a></li>
                                                <li><a href="javascript:void(0)" className={"page-numbers" + (items.length != perPage ? ' padination-disabled' : '')} onClick={() => setPage(page + 1)}> Next &#62;&#62;</a></li>
                                                {/* <li><a className="page-numbers">5</a></li>
                                            <li><a className="page-numbers">6</a></li>
                                            <li><a className="next page-numbers"
                                                    href="#/shop/page/2/">→</a></li> */}
                                            </ul>
                                        </nav>
                                        :
                                        <div className="product-unavailable"> No Items Available.</div>
                                }

                            </div>
                            <div id="sidebar" className="col-12 col-md-3 col-lg-3 content-secondary site-sidebar">
                                <div className="site-scroll ps">
                                    <div className="sidebar-inner">

                                        <div className="sidebar-mobile-header">
                                            <h3 className="entry-title">Filter Products</h3>

                                            <div className="close-sidebar">
                                                <i className="klbth-icon-x"></i>
                                            </div>
                                        </div>

                                        <div className="widget widget_klb_product_categories">
                                            <h4 className="widget-title">Product Categories</h4>
                                            <div className="widget-body site-checkbox-lists ">
                                                <div className="site-scroll ps">
                                                    <ul>
                                                        <li className="cat-parent">
                                                            <a href="#/shop/?add-to-cart=430&amp;filter_cat=31" className="product_cat category_parent">
                                                                <input onChange={() => { }} name="product_cat[]" value="31" id="main-cat" type="checkbox" />
                                                                <label htmlFor="main-cat"> {category?.main_category?.category_name} </label>
                                                            </a>
                                                            <ul className="children">
                                                                {
                                                                    category?.subcategory?.length > 0 ? category?.subcategory.map((sub_cat, index) => {
                                                                        return (
                                                                            <li key={index}>
                                                                                {/* <a  > */}
                                                                                <input className="category-checkbox" onChange={(e) => handleCategoryChange(e)}
                                                                                    name="product_cat" value={sub_cat.slug} id={"cat" + index}
                                                                                    type="checkbox" checked={selectedCategories.includes(sub_cat.slug)} /><label htmlFor={"cat" + index}>  {sub_cat.category_name}</label>
                                                                                {/* </a> */}
                                                                            </li>
                                                                        );
                                                                    })
                                                                        : null
                                                                }
                                                            </ul>
                                                            <span className="subDropdown plus"></span>
                                                        </li>
                                                    </ul>
                                                    <div className="ps__rail-x" style={{ left: '0px', bottom: '0px' }}>
                                                        <div className="ps__thumb-x" tabIndex="0"
                                                            style={{ left: '0px', width: '0px' }}></div>
                                                    </div>
                                                    <div className="ps__rail-y" style={{ top: '0px', right: '0px' }}>
                                                        <div className="ps__thumb-y" tabIndex="0"
                                                            style={{ top: '0px', height: '0px' }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widget woocommerce widget_price_filter">
                                            <h4 className="widget-title">Filter by price</h4>
                                            <form method="get" action="#/shop/">
                                                <div className="price_slider_wrapper">
                                                   
                                                    <label className="screen-reader-text" htmlFor="min_price">Min price</label>
                                                        <input onChange={(e) => setMinPrice(e.target.value)} type="text" style={{width:'35%', margin:' 0 10px 10px 10px'}} id="min_price" name="min_price" value={minPrice}
                                                            data-min="0" placeholder="Min price"  />
                                                        <label className="screen-reader-text" htmlFor="max_price">Max
                                                            price</label>
                                                        <input onChange={(e)=> setMaxPrice(e.target.value)} type="text" id="max_price" name="max_price" value={maxPrice}
                                                            data-max="70" style={{width:'35%'}} placeholder="Max price" />
                                                        <div className="price_label" style={{width:'fit-content', display:'inline-block', marginRight:'5px'}} >
                                                            Price: <span className="from">${minPrice}</span> — <span
                                                                className="to">${maxPrice}</span>
                                                        </div>
                                                        <button type="button" onClick={() => handlePriceFilter()} className="button">Filter</button>
                                                    
                                                    {/* <div className="price_slider_amount" data-step="10">
                                                        <label className="screen-reader-text" htmlFor="min_price">Min
                                                            price</label>
                                                        <input onChange={()=>{}} type="text" id="min_price" name="min_price" value="0"
                                                            data-min="0" placeholder="Min price" style={{display: 'none'}} />
                                                        <label className="screen-reader-text" htmlFor="max_price">Max
                                                            price</label>
                                                        <input onChange={()=>{}} type="text" id="max_price" name="max_price" value="70"
                                                            data-max="70" placeholder="Max price"
                                                            style={{display: 'none'}} />
                                                        <button type="submit" className="button">Filter</button>
                                                        <div className="price_label" >
                                                            Price: <span className="from">$0</span> — <span
                                                                className="to">$70</span>
                                                        </div>
                                                        <input onChange={()=>{}} type="hidden" name="add-to-cart" value="430" />
                                                        <div className="clear"></div>
                                                    </div> */}
                                                </div>
                                            </form>

                                        </div>

                                        {/* <div className="widget widget_product_status">
                                            <h4 className="widget-title">Product Status</h4>
                                            <div className="widget-body site-checkbox-lists">
                                                <div className="site-scroll ps">
                                                    <ul>
                                                        <li><a
                                                                href="#/shop/?add-to-cart=430&amp;stock_status=instock"><input onChange={()=>{}}
                                                                    name="stockonsale" value="instock" id="instock"
                                                                    type="checkbox" /><label><span></span>In
                                                                    Stock</label></a></li>
                                                        <li><a
                                                                href="#/shop/?add-to-cart=430&amp;on_sale=onsale"><input onChange={()=>{}}
                                                                    name="stockonsale" value="onsale" id="onsale"
                                                                    type="checkbox" /><label><span></span>On
                                                                    Sale</label></a></li>
                                                    </ul>
                                                    <div className="ps__rail-x" style={{left: '0px', bottom: '0px'}}>
                                                        <div className="ps__thumb-x" tabIndex="0"
                                                            style={{left: '0px', width: '0px'}}></div>
                                                    </div>
                                                    <div className="ps__rail-y" style={{top: '0px', right: '0px'}}>
                                                        <div className="ps__thumb-y" tabIndex="0"
                                                            style={{top: '0px', height: '0px'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div
                                            className="widget woocommerce widget_layered_nav woocommerce-widget-layered-nav">
                                            <h4 className="widget-title">Brands</h4>
                                            <ul className={"woocommerce-widget-layered-nav-list" + ( showAllBrands ? ' brand-expand' : ' brand-collapse')} ref={brandRef} onScroll={(e) => console.log("Brand scroll",e)}>
                                                {
                                                    brands?.map((brand, index) => {
                                                        return (
                                                            <li className="woocommerce-widget-layered-nav-list__item wc-layered-nav-term ">
                                                                <input className="category-checkbox" onChange={() => handleBrandChange(brand.id)} name="product_brands" id={"brand-" + index} value={brand.brand_name} type="checkbox" />
                                                                <label htmlFor={"brand-" + index}>  {brand.brand_name}</label>

                                                            </li>
                                                        );
                                                    })

                                                }
                                            </ul>
                                            {
                                                brands.length > 10 ?
                                                    <span className="brand-see-more" onClick={() => setShowAllBrands(!showAllBrands)}> {showAllBrands ? 'see less' : 'see more'} </span>
                                                :null
                                            }
                                        </div>
                                        <div className="widget widget_media_image"><img width="1280" height="1750"
                                            src="/images/sidebar-banner.gif"
                                            className="image wp-image-1184  attachment-full size-full" alt=""
                                            loading="lazy" style={{ maxWidth: '100%', height: 'auto' }} /></div>
                                    </div>
                                    <div className="ps__rail-x" style={{ left: '0px', bottom: '0px' }}>
                                        <div className="ps__thumb-x" tabIndex="0" style={{ left: '0px', width: '0px' }}></div>
                                    </div>
                                    <div className="ps__rail-y" style={{ top: '0px', right: '0px' }}>
                                        <div className="ps__thumb-y" tabIndex="0" style={{ top: '0px', height: '0px' }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <RecentViewProducts prod_id={params.id} />
                </div>
            </div>
        </main>
    );
}

export default ProductCategory;