import { Link } from "react-router-dom";

const CanvasHeader = () =>{
    return (
        <div className="canvas-header">
            <div className="site-brand">
               <Link to="/" title="Bacola – Grocery Market and Food Theme">
                  <img className="img-fluid" src="/images/bacola-logo.png" alt="Bacola – Grocery Market and Food Theme" />

               </Link>
            </div>
            <div className="close-canvas">
               <i className="klbth-icon-x"></i>
            </div>
         </div>
    );
}

export default CanvasHeader;