import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"
import { retriveCategories } from "../../services/CategoryService";

const HeaderNavbar = () => {

   const [categories, setcategories] = useState([]);

   useEffect(()=>{
      getCategories()
   },[]);

   const getCategories = async() =>{
      await retriveCategories().then((response)=>{
         if(response.data.data){
            setcategories(response.data.data);
         }
      
     }).catch((error) =>{
         console.log("Error", error.response)
     });
   }

   const hideCategoryDropdown = () =>{
      document.getElementById("all-categories").classList.remove("show")
   }

   return (
      <>
      <div className="header-nav header-wrapper hide-mobile">
         <div className="container">


            <div className="all-categories locked">
               <a href="" onClick={(e) => {e.preventDefault(); document.getElementById("all-categories").classList.toggle("show")}} data-toggle="collapse" data-target="#all-categories">
                  <i className="klbth-icon-menu-thin"></i>
                  <span className="text">ALL CATEGORIES</span>

                  <div className="description">TOTAL 63 PRODUCTS</div>
               </a>

               <div className="dropdown-categories collapse" id="all-categories">
                  <ul id="menu-sidebar-menu-1" className="menu-list">
                        {
                           categories.map((category, index) => {
                              if(category.parent_id == null){
                              return (
                                 <li key={index} className={"category-parent menu-item menu-item-type-taxonomy menu-item-object-product_cat" + (categories.filter((el) => el.parent_id == category.id).length > 0 ? ' parent' : '')}>
                                    <Link onClick={()=> hideCategoryDropdown() } to={"/product-category/" + category.slug}>
                                       {/* <i className="klbth-icon-biscuit"></i>  */}
                                       {category.category_name}
                                    </Link>
                                    {
                                       categories.filter((el) => el.parent_id == category.id).length > 0 ?
                                          <ul className="sub-menu">
                                             {categories.map((sub_cat, index) => {
                                                if(sub_cat.parent_id == category.id){
                                                return (

                                                   <li key={index} className={"category-parent  menu-item menu-item-type-taxonomy menu-item-object-product_cat" }>
                                                      <Link onClick={()=> hideCategoryDropdown()} to={"/product-category/" + category.slug  + "/" + sub_cat.slug}>
                                                         {sub_cat.category_name}
                                                      </Link>
                                                   </li>
                                                )}
                                             })}
                                          </ul>

                                          : null
                                    }
                                 </li>
                              )}
                           })
                        }

                     {/* <li className="link-parent  menu-item menu-item-type-custom menu-item-object-custom"><a
                        href="#/shop/?on_sale=onsale"><i className="bottom"></i> Value of the
                        Day</a></li>
                     <li className="link-parent  menu-item menu-item-type-custom menu-item-object-custom"><a
                        href="#/shop/?filter_cat=58,35,31"><i className="bottom"></i> Top 100
                        Offers</a></li>
                     <li className="link-parent  menu-item menu-item-type-custom menu-item-object-custom"><a
                        href="#/shop/"><i className="bottom"></i> New Arrivals</a></li> */}
                  </ul>
               </div>

            </div>

            <nav className="site-menu primary-menu horizontal">
               <ul id="menu-menu-2" className="menu">
                     <li
                        className="dropdown  menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-parent">
                        <Link to="/"> Home </Link>
                     </li>
                  {/* <li
                     className="dropdown mega-menu menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children">
                     <Link to="/shop"> Shop </Link>
                     <ul className="sub-menu" style={{ paddingLeft: '105.5px', paddingRight: '105.5px' }}>
                        <li
                           className="dropdown  menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children">
                           <a href="#/shop/"><span className="text">Shop Lists</span></a>
                           <ul className="sub-menu">
                              <li className=" menu-item menu-item-type-post_type menu-item-object-page"><a
                                 href="#/shop/"><span className="text">Shop Default</span></a>
                              </li>
                              <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                 href="#/shop/?opt=right-sidebar"><span className="text">Shop
                                    Right Sidebar</span></a></li>
                              <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                 href="#/shop/?column=5&amp;opt=wide"><span
                                    className="text">Shop Wide</span></a></li>
                              <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                 href="#/shop/?shop_view=list_view"><span className="text">List
                                    Left Sidebar</span></a></li>
                              <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                 href="#/shop/?ft=load-more"><span className="text">Load More
                                    Button</span></a></li>
                              <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                 href="#/shop/?ft=infinite"><span className="text">Infinite
                                    Scrolling</span></a></li>
                           </ul>
                        </li>
                        <li
                           className="dropdown  menu-item menu-item-type-post_type menu-item-object-product menu-item-has-children">
                           <a href="#/product/all-natural-italian-style-chicken-meatballs/"><span
                              className="text">Product Detail</span></a>
                           <ul className="sub-menu">
                              <li className=" menu-item menu-item-type-post_type menu-item-object-product"><a
                                 href="#/product/all-natural-italian-style-chicken-meatballs/"><span
                                    className="text">Product Default</span></a></li>
                              <li className=" menu-item menu-item-type-post_type menu-item-object-product"><a
                                 href="#/product/tomatoes-on-the-vine/"><span
                                    className="text">Product Variable</span></a></li>
                              <li className=" menu-item menu-item-type-post_type menu-item-object-product"><a
                                 href="#/product/foster-farms-takeout-crispy-classic-buffalo-wings/"><span
                                    className="text">Product Grouped</span></a></li>
                              <li className=" menu-item menu-item-type-post_type menu-item-object-product"><a
                                 href="#/product/zevia-kidz-strawberry-lemonade-zero-calorie-soda/"><span
                                    className="text">Product External</span></a></li>
                              <li className=" menu-item menu-item-type-post_type menu-item-object-product"><a
                                 href="#/product/vital-farms-pasture-raised-egg-bites-bacon-cheddar/"><span
                                    className="text">Product Downloadable</span></a></li>
                              <li className=" menu-item menu-item-type-post_type menu-item-object-product"><a
                                 href="#/product/angies-boomchickapop-sweet-salty-kettle-corn/"><span
                                    className="text">Product With Video</span></a></li>
                           </ul>
                        </li>
                        <li
                           className="dropdown  menu-item menu-item-type-post_type menu-item-object-product menu-item-has-children">
                           <a href="#/product/all-natural-italian-style-chicken-meatballs/"><span
                              className="text">Product Types</span></a>
                           <ul className="sub-menu">
                              <li className=" menu-item menu-item-type-post_type menu-item-object-product"><a
                                 href="#/product/all-natural-italian-style-chicken-meatballs/"><span
                                    className="text">Single Type 1</span></a></li>
                              <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                 href="#/product/angies-boomchickapop-sweet-salty-kettle-corn/?opt=type2"><span
                                    className="text">Single Type 2</span></a></li>
                              <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                 href="#/product/blue-diamond-almonds-lightly-salted/?opt=type3"><span
                                    className="text">Single Type 3</span></a></li>
                              <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                 href="#/product/organic-white-corn-tortilla-chips/?opt=type4"><span
                                    className="text">Single Type 4</span></a></li>
                              <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                 href="#/product/all-natural-italian-style-chicken-meatballs/?opt=vertical"><span
                                    className="text">Thumbnails Left</span></a></li>
                              <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                 href="#/product/organic-white-corn-tortilla-chips/?ft=imgzoom"><span
                                    className="text">Zoom Image</span></a></li>
                           </ul>
                        </li>
                        <li
                           className="dropdown  menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                           <a href="#/home-3/#"><span className="text">Shop Pages</span></a>
                           <ul className="sub-menu">
                              <li className=" menu-item menu-item-type-post_type menu-item-object-page"><a
                                 href="#/cart/"><span className="text">Cart</span></a></li>
                              <li className=" menu-item menu-item-type-post_type menu-item-object-page"><a
                                 href="#/checkout/"><span className="text">Checkout</span></a>
                              </li>
                              <li className=" menu-item menu-item-type-post_type menu-item-object-page"><a
                                 href="#/my-account/"><span className="text">My
                                    account</span></a></li>
                              <li className=" menu-item menu-item-type-post_type menu-item-object-page"><a
                                 href="#/wishlist/"><span className="text">Wishlist</span></a>
                              </li>
                              <li className=" menu-item menu-item-type-post_type menu-item-object-page"><a
                                 href="#/order-tracking/"><span className="text">Order
                                    Tracking</span></a></li>
                              <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                 href="#/product/blue-diamond-almonds-lightly-salted/?ft=orderonwhatsapp"><span
                                    className="text">Order on WhatsApp</span></a></li>
                           </ul>
                        </li>
                        <li
                           className="dropdown  menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                           <a href="#/home-3/#"><span className="text">Shop Layouts</span></a>
                           <ul className="sub-menu">
                              <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                 href="#/shop/?column=2"><span className="text">Two
                                    Columns</span></a></li>
                              <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                 href="#/shop/?column=3"><span className="text">Three
                                    Columns</span></a></li>
                              <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                 href="#/shop/?column=3&amp;opt=wide"><span
                                    className="text">Three Columns Wide</span></a></li>
                              <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                 href="#/shop/?column=4"><span className="text">Four
                                    Columns</span></a></li>
                              <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                 href="#/shop/?column=4&amp;opt=wide"><span
                                    className="text">Four Columns Wide</span></a></li>
                              <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                 href="#/shop/?column=5&amp;opt=wide"><span
                                    className="text">Five Columns wide</span></a></li>
                           </ul>
                        </li>
                     </ul>
                  </li> */}
                  <li className=" menu-item menu-item-type-taxonomy menu-item-object-product_cat"> 
                     <Link to="/product-category/edibles"> <i className="klbth-icon-meat"></i> Edibles </Link> 
                  </li>
                  <li className=" menu-item menu-item-type-taxonomy menu-item-object-product_cat"> 
                     <Link to="/product-category/flower-pre-rolls"> <i className="klbth-icon-forumbee"></i> Flower & Pre-Rolls </Link> 
                  </li>
                  <li className=" menu-item menu-item-type-taxonomy menu-item-object-product_cat">
                     <Link to="/product-category/tinctures"> <i
                        className="klbth-icon-biscuit"></i> Tinctures </Link>
                     </li>
                  <li className=" menu-item menu-item-type-taxonomy menu-item-object-product_cat">
                     <Link to="/product-category/vape"> <i className="klbth-icon-cup"></i>
                     Vape </Link>
                     </li>
                  {/* <li className=" menu-item menu-item-type-post_type menu-item-object-page">
                     <Link to="blog"> Blog </Link>
                     </li> */}
                  <li className="menu-item menu-item-type-post_type menu-item-object-page">
                     <Link to="/contact"> 
                     {/* <i className="klbth-icon-contact"></i>  */}
                     Contact  </Link>
                  </li> 
               </ul>
            </nav>
         </div>
      </div>
      </>
   );
}

export default HeaderNavbar;