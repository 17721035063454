const Navbar = () => {
    return (
        <nav className="canvas-menu canvas-primary vertical">
            <ul id="menu-menu-1" className="menu">
                <li
                    className="dropdown  menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children">
                    <a href="#/">Home</a>
                    <ul className="sub-menu">
                        <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-home"><a
                            href="#/">Home 1</a></li>
                        <li className=" menu-item menu-item-type-post_type menu-item-object-page"><a
                            href="#/home-2/">Home 2</a></li>
                        <li
                            className=" menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-1047 current_page_item">
                            <a href="#/home-3/">Home 3</a>
                        </li>
                        <li className=" menu-item menu-item-type-post_type menu-item-object-page"><a
                            href="#/home-4/">Home 4</a></li>
                        <li className=" menu-item menu-item-type-post_type menu-item-object-page"><a
                            href="#/home-5/">Home 5</a></li>
                    </ul>
                    <span className="menu-dropdown"><i className="klbth-icon-down-open-big"></i></span>
                </li>
                <li
                    className="dropdown mega-menu menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children">
                    <a href="#/shop/">Shop</a>
                    <ul className="sub-menu">
                        <li
                            className="dropdown  menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children">
                            <a href="#/shop/">Shop Lists</a>
                            <ul className="sub-menu">
                                <li className=" menu-item menu-item-type-post_type menu-item-object-page"><a
                                    href="#/shop/">Shop Default</a></li>
                                <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                    href="#/shop/?opt=right-sidebar">Shop Right Sidebar</a>
                                </li>
                                <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                    href="#/shop/?column=5&amp;opt=wide">Shop Wide</a></li>
                                <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                    href="#/shop/?shop_view=list_view">List Left Sidebar</a>
                                </li>
                                <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                    href="#/shop/?ft=load-more">Load More Button</a></li>
                                <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                    href="#/shop/?ft=infinite">Infinite Scrolling</a></li>
                            </ul>
                            <span className="menu-dropdown"><i className="klbth-icon-down-open-big"></i></span>
                        </li>
                        <li
                            className="dropdown  menu-item menu-item-type-post_type menu-item-object-product menu-item-has-children">
                            <a href="#/product/all-natural-italian-style-chicken-meatballs/">Product
                                Detail</a>
                            <ul className="sub-menu">
                                <li className=" menu-item menu-item-type-post_type menu-item-object-product"><a
                                    href="#/product/all-natural-italian-style-chicken-meatballs/">Product
                                    Default</a></li>
                                <li className=" menu-item menu-item-type-post_type menu-item-object-product"><a
                                    href="#/product/tomatoes-on-the-vine/">Product
                                    Variable</a></li>
                                <li className=" menu-item menu-item-type-post_type menu-item-object-product"><a
                                    href="#/product/foster-farms-takeout-crispy-classNameic-buffalo-wings/">Product
                                    Grouped</a></li>
                                <li className=" menu-item menu-item-type-post_type menu-item-object-product"><a
                                    href="#/product/zevia-kidz-strawberry-lemonade-zero-calorie-soda/">Product
                                    External</a></li>
                                <li className=" menu-item menu-item-type-post_type menu-item-object-product"><a
                                    href="#/product/vital-farms-pasture-raised-egg-bites-bacon-cheddar/">Product
                                    Downloadable</a></li>
                                <li className=" menu-item menu-item-type-post_type menu-item-object-product"><a
                                    href="#/product/angies-boomchickapop-sweet-salty-kettle-corn/">Product
                                    With Video</a></li>
                            </ul>
                            <span className="menu-dropdown"><i className="klbth-icon-down-open-big"></i></span>
                        </li>
                        <li
                            className="dropdown  menu-item menu-item-type-post_type menu-item-object-product menu-item-has-children">
                            <a href="#/product/all-natural-italian-style-chicken-meatballs/">Product
                                Types</a>
                            <ul className="sub-menu">
                                <li className=" menu-item menu-item-type-post_type menu-item-object-product"><a
                                    href="#/product/all-natural-italian-style-chicken-meatballs/">Single
                                    Type 1</a></li>
                                <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                    href="#/product/angies-boomchickapop-sweet-salty-kettle-corn/?opt=type2">Single
                                    Type 2</a></li>
                                <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                    href="#/product/blue-diamond-almonds-lightly-salted/?opt=type3">Single
                                    Type 3</a></li>
                                <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                    href="#/product/organic-white-corn-tortilla-chips/?opt=type4">Single
                                    Type 4</a></li>
                                <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                    href="#/product/all-natural-italian-style-chicken-meatballs/?opt=vertical">Thumbnails
                                    Left</a></li>
                                <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                    href="#/product/organic-white-corn-tortilla-chips/?ft=imgzoom">Zoom
                                    Image</a></li>
                            </ul>
                            <span className="menu-dropdown"><i className="klbth-icon-down-open-big"></i></span>
                        </li>
                        <li
                            className="dropdown  menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                            <a href="#/home-3/#">Shop Pages</a>
                            <ul className="sub-menu">
                                <li className=" menu-item menu-item-type-post_type menu-item-object-page"><a
                                    href="#/cart/">Cart</a></li>
                                <li className=" menu-item menu-item-type-post_type menu-item-object-page"><a
                                    href="#/checkout/">Checkout</a></li>
                                <li className=" menu-item menu-item-type-post_type menu-item-object-page"><a
                                    href="#/my-account/">My account</a></li>
                                <li className=" menu-item menu-item-type-post_type menu-item-object-page"><a
                                    href="#/wishlist/">Wishlist</a></li>
                                <li className=" menu-item menu-item-type-post_type menu-item-object-page"><a
                                    href="#/order-tracking/">Order Tracking</a></li>
                                <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                    href="#/product/blue-diamond-almonds-lightly-salted/?ft=orderonwhatsapp">Order
                                    on WhatsApp</a></li>
                            </ul>
                            <span className="menu-dropdown"><i className="klbth-icon-down-open-big"></i></span>
                        </li>
                        <li
                            className="dropdown  menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                            <a href="#/home-3/#">Shop Layouts</a>
                            <ul className="sub-menu">
                                <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                    href="#/shop/?column=2">Two Columns</a></li>
                                <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                    href="#/shop/?column=3">Three Columns</a></li>
                                <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                    href="#/shop/?column=3&amp;opt=wide">Three Columns
                                    Wide</a></li>
                                <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                    href="#/shop/?column=4">Four Columns</a></li>
                                <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                    href="#/shop/?column=4&amp;opt=wide">Four Columns Wide</a>
                                </li>
                                <li className=" menu-item menu-item-type-custom menu-item-object-custom"><a
                                    href="#/shop/?column=5&amp;opt=wide">Five Columns wide</a>
                                </li>
                            </ul>
                            <span className="menu-dropdown"><i className="klbth-icon-down-open-big"></i></span>
                        </li>
                    </ul>
                    <span className="menu-dropdown"><i className="klbth-icon-down-open-big"></i></span>
                </li>
                <li className=" menu-item menu-item-type-taxonomy menu-item-object-product_cat"><a
                    href="#/product-category/meats-seafood/"><i
                        className="klbth-icon-meat"></i> Meats &amp; Seafood</a></li>
                <li className=" menu-item menu-item-type-taxonomy menu-item-object-product_cat"><a
                    href="#/product-category/breads-bakery/"><i
                        className="klbth-icon-biscuit"></i> Bakery</a></li>
                <li className=" menu-item menu-item-type-taxonomy menu-item-object-product_cat"><a
                    href="#/product-category/beverages/"><i className="klbth-icon-cup"></i>
                    Beverages</a></li>
                <li className=" menu-item menu-item-type-post_type menu-item-object-page"><a
                    href="#/blog/">Blog</a></li>
                <li className=" menu-item menu-item-type-post_type menu-item-object-page"><a
                    href="#/contact/">Contact</a></li>
            </ul>
        </nav>
    );
}


export default Navbar;