import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { lostPassword } from "../../services/AuthService";
import { randomString } from "../../utils/constant";

const LostPassword = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
        if (email == '') {
            toast("Please enter email.", {
                type: 'error'
            });
            return false;
        }
        let token = randomString(32);
        setIsLoading(true);
        await lostPassword(email,token).then((response) => {
            localStorage.setItem('pass-reset-token', token);
            toast('Email Sent Successfully. Please Check Your Mail Inbox.', { type: 'success' })
        }).catch((error) => {
            console.log("Error", error.response)
            if (error.response.status == 422)
                toast(error.response.data.error, { type: 'error' })
            else if (error.response.status == 500)
                toast(error.response.data.message, { type: 'error' })
            else
                toast("Something went wrong", { type: 'error' });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <main id="main" className="site-primary">
            <div className="site-content">
                <div className="homepage-content">
                    <div className="shop-content my-account-page">
                        <div className="container">
                            <nav className="woocommerce-breadcrumb">
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link href="/my-account">My account</Link>
                                    </li>
                                    <li>Lost password</li>
                                </ul>
                            </nav>
                            <div className="woocommerce">
                                <div className="woocommerce-notices-wrapper" />
                                <form
                                    className="woocommerce-ResetPassword lost_reset_password">
                                    <p>
                                        Lost your password? Please enter your email address.
                                        You will receive a link to create a new password via email.
                                    </p>
                                    <p className="woocommerce-form-row woocommerce-form-row--first form-row form-row-first">
                                        <label htmlFor="user_login">Username or email</label>
                                        <input
                                            className="woocommerce-Input woocommerce-Input--text input-text"
                                            type="email"
                                            name="email"
                                            id="user_login"
                                            autoComplete="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </p>

                                    <p className="woocommerce-form-row form-row">
                                        <button
                                            type="button"
                                            disabled={isLoading}
                                            style={{opacity:isLoading ? '0.7' : '1'}}
                                            className="woocommerce-Button button wp-element-button"
                                            value="Reset password"
                                            onClick={() => handleSubmit()}
                                        >
                                            Reset password 
                                            {   
                                                isLoading ?
                                                    <img style={{marginLeft:'10px'}} src="/images/loader.gif" width='25' alt="loader" />
                                                : null
                                            } 
                                        </button>
                                    </p>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* homepage-content */}
            </div>
            {/* site-content */}
        </main>

    );
}


export default LostPassword;