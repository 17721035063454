import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { retriveAccountDetails, saveAccountDetails } from "../../services/CustomerService";
import Navigation from "./Navigation";

const AccountDetails = () => {
  const [details, setDetails] = useState({});

  useEffect(() => {
    getAccountDetails();
  }, []);

  const getAccountDetails = async () => {
    await retriveAccountDetails().then((response) => {
      setDetails(response.data.data);
    }).catch((error) => {
      console.log("Error", error);
    })
  }

  const handleDetailsChange = (e) => {
    let { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  }

  const handleSubmit = async () => {
    if (validateInput()) {
      await saveAccountDetails(details).then((response) => {
        toast("Details Updated Successfully.", { type: 'success' });
      }).catch((error) => {
        console.log("Error", error);
        if (error.response.status == 422)
          toast(error.response.data.error, { type: 'error' })
        else if (error.response.status == 500)
          toast(error.response.data.message, { type: 'error' })
        else
          toast("Something went wrong", { type: 'error' });
      })

    }
  }

  const validateInput = () => {
    if (details.name == '') {
      toast("Please enter name.", {
        type: 'error'
      });
      return false
    }
    if (details.email == '') {
      toast("Please enter email.", {
        type: 'error'
      });
      return false
    }

    if (details.company_name == '') {
      toast("Please enter company name.", {
        type: 'error'
      });
      return false
    }
    
    if (details.phone == '') {
      toast("Please enter phone.", {
        type: 'error'
      });
      return false
    }

    if (details.password_current != '') {
      if (!details.hasOwnProperty('new_password') || details.new_password == '') {
        toast("Please enter new password.", {
          type: 'error'
        });
        return false;
      }
      if (!details.hasOwnProperty('new_password_confirmation') || details.new_password_confirmation == '') {
        toast("Please confirm password .", {
          type: 'error'
        });
        return false;
      }

      if (details.new_password != details.new_password_confirmation) {
        toast("Password do not match .", {
          type: 'error'
        });
        return false;
      }
    }

    return true;
  }

  return (
    <main id="main" className="site-primary">
      <div className="site-content">
        <div className="homepage-content">
          <div className="shop-content my-account-page">
            <div className="container">
              <nav className="woocommerce-breadcrumb">
                <ul>
                  <li>
                    <a href="">Home</a>
                  </li>
                  <li>
                    <a href="/my-account">My account</a>
                  </li>
                  <li>Account details</li>
                </ul>
              </nav>
              <div className="woocommerce">
                <div className="row content-wrapper sidebar-right">
                  <div className="col-12 col-md-12 col-lg-12 content-primary">
                    <div className="my-account-wrapper">
                      {" "}
                      {/* my-account-wrapper be closed in myaccount.php */}
                      <div className="my-account-navigation">
                        <div className="account-toggle-menu">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <line x1="2.3" y1={12} x2="21.8" y2={12} />
                            <line x1="2.3" y1={6} x2="21.8" y2={6} />
                            <line x1="2.3" y1={18} x2="21.8" y2={18} />
                          </svg>
                          Navigation{" "}
                        </div>
                        {/* account-toggle-menu */}
                        <Navigation />
                      </div>
                      <div className="woocommerce-MyAccount-content">
                        <div className="woocommerce-notices-wrapper" />
                        <form
                          className="woocommerce-EditAccountForm edit-account"
                          autoComplete="off"
                        >
                          <p className="woocommerce-form-row woocommerce-form-row--first form-row form-row-first">
                            <label htmlFor="account_first_name">
                              Full name&nbsp;<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="woocommerce-Input woocommerce-Input--text input-text"
                              name="name"
                              onChange={(e) => handleDetailsChange(e)}
                              value={details.name}
                              id="account_first_name"
                              autoComplete="given-name"
                            />
                          </p>

                          <div className="clear" />
                          <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                            <label htmlFor="account_display_name">
                              Company name&nbsp;<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="woocommerce-Input woocommerce-Input--text input-text"
                              name="company_name"
                              onChange={(e) => handleDetailsChange(e)}
                              value={details.company_name}
                              id="account_display_name"
                            />{" "}
                            <span>
                              <em>
                                This will be how your name will be displayed in the
                                account section and in reviews
                              </em>
                            </span>
                          </p>
                          <div className="clear" />
                          <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                            <label htmlFor="account_email">
                              Email address&nbsp;<span className="required">*</span>
                            </label>
                            <input
                              type="email"
                              className="woocommerce-Input woocommerce-Input--email input-text"
                              name="email"
                              onChange={(e) => handleDetailsChange(e)}
                              value={details.email}
                              id="account_email"
                              autoComplete="email"

                            />
                          </p>
                          
                          <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                            <label htmlFor="account_email">
                              Phone&nbsp;<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="woocommerce-Input woocommerce-Input--email input-text"
                              name="phone"
                              onChange={(e) => handleDetailsChange(e)}
                              value={details.phone ?? ''}
                              id="account_email"
                              autoComplete="phone"

                            />
                          </p>
                          <fieldset>
                            <legend>Password change</legend>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="password_current">
                                Current password (leave blank to leave unchanged)
                              </label>
                              <span className="password-input">
                                <input
                                  type="password"
                                  className="woocommerce-Input woocommerce-Input--password input-text"
                                  name="password_current"
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.password_current ? details.password_current : ''}
                                  // id="password_current"
                                  autoComplete="do-not-autofill"
                                  aria-autocomplete="none"
                                />
                                <span className="show-password-input" />
                              </span>
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="password_1">
                                New password (leave blank to leave unchanged)
                              </label>
                              <span className="password-input">
                                <input
                                  type="password"
                                  className="woocommerce-Input woocommerce-Input--password input-text"
                                  name="new_password"
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.new_password}
                                  id="password_1"
                                  autoComplete="off"
                                />
                                <span className="show-password-input" />
                              </span>
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                              <label htmlFor="password_2">
                                Confirm new password
                              </label>
                              <span className="password-input">
                                <input
                                  type="password"
                                  className="woocommerce-Input woocommerce-Input--password input-text"
                                  name="new_password_confirmation"
                                  onChange={(e) => handleDetailsChange(e)}
                                  value={details.new_password_confirmation}
                                  id="password_2"
                                  autoComplete="off"
                                />
                                <span className="show-password-input" />
                              </span>
                            </p>
                          </fieldset>
                          <div className="clear" />
                          <p>

                            <button
                              type="button"
                              className="woocommerce-Button button"
                              name="save_account_details"
                              value="Save changes"
                              onClick={() => handleSubmit()}
                            >
                              Save changes
                            </button>

                          </p>
                        </form>
                      </div>
                    </div>{" "}
                    {/* my-account-wrapper closed in navigation.php */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* homepage-content */}
      </div>
      {/* site-content */}
    </main>

  );
}

export default AccountDetails;