import { useState } from "react";

const Banner = () => {
    const [bannerTranslate, setBannerTranslate] = useState(0);
    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-384d7d2 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="384d7d2" data-element_type="section">
            <div className="elementor-container elementor-column-gap-no">
                <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b4a0bb1"
                    data-id="b4a0bb1" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-f643bef elementor-widget elementor-widget-bacola-home-slider"
                            data-id="f643bef" data-element_type="widget"
                            data-widget_type="bacola-home-slider.default">
                            <div className="elementor-widget-container">
                                <div className="site-module module-slider full-width wide ">
                                    <div className="module-body">
                                        <div className="slider-wrapper slider-loaded"><svg className="preloader" width="65px"
                                            height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round"
                                                cx="33" cy="33" r="30"></circle>
                                        </svg>
                                            <div className="site-slider slick-initialized slick-slider" data-slideshow="1"
                                                data-slidespeed="1200" data-autoplay="false" data-autospeed="" data-dots=""
                                                data-arrows="true"><button className="slick-prev slick-arrow"
                                                    aria-label="Previous" type="button" onClick={() => { if(bannerTranslate >= 0){ setBannerTranslate(0)}else{setBannerTranslate(bannerTranslate + 500)}}} >Previous</button>
                                                <div className="slick-list draggable">
                                                    <div className="slick-track add-trans"
                                                        style={{ opacity: '1', width: '5615px', transform: `translate3d(${bannerTranslate}px, 0px, 0px)`}}>
                                                       {/*  <div className="slick-slide slick-cloned" style={{ width: '1123px' }}
                                                            data-slick-index="-1" id="" aria-hidden="true" tabIndex="-1">
                                                            <div className="slider-item">
                                                                <div className="content-wrapper">
                                                                    <div className="content-header">
                                                                        <div className="content-description">Exclusive Offer</div>
                                                                        <div className="content-discount">-40% Off</div>
                                                                    </div>
                                                                    <div className="content-main">
                                                                        <h3 className="entry-title">Quality Freshness Guaranteed!
                                                                        </h3>
                                                                        <div className="content-text">Only this week. Don’t miss...
                                                                        </div>
                                                                    </div>
                                                                    <div className="content-footer"><span className="price-text">from
                                                                    </span><span className="price">$14.35</span></div><a
                                                                        href="/product/112/zevia-kidz-strawberry-lemonade-zero-calorie-soda/"
                                                                        className="button button-secondary rounded"
                                                                        tabIndex="-1">Shop Now <i
                                                                            className="klbth-icon-right-arrow"></i></a>
                                                                </div>
                                                                <div className="image-wrapper"><img
                                                                    src="/images/d8-goliath-web-banner.jpg" alt="bacola" />
                                                                </div><a
                                                                    href="/product/112/zevia-kidz-strawberry-lemonade-zero-calorie-soda/"
                                                                    className="overlay-link" tabIndex="-1"></a>
                                                            </div>
                                                        </div> */}
                                                        <div className="slick-slide slick-current slick-active"
                                                            style={{ width: '1123px' }} data-slick-index="0" aria-hidden="false"
                                                            tabIndex="0">
                                                            <div className="slider-item">
                                                                {/* <div className="content-wrapper">
                                                  <div className="content-header">
                                                     <div className="content-description">Exclusive Offer</div>
                                                     <div className="content-discount">-20% Off</div>
                                                  </div>
                                                  <div className="content-main">
                                                     <h3 className="entry-title">A different kind of grocery store
                                                     </h3>
                                                     <div className="content-text">Only this week. Don’t miss...
                                                     </div>
                                                  </div>
                                                  <div className="content-footer"><span className="price-text">from
                                                     </span><span className="price">$7.99</span></div><a
                                                     href="/product/112/all-natural-italian-style-chicken-meatballs/"
                                                     className="button button-secondary rounded" tabIndex="0">Shop
                                                     Now <i className="klbth-icon-right-arrow"></i></a>
                                               </div> */}
                                                                <div className="image-wrapper"><img
                                                                    src="/images/d8-goliath-web-banner.jpg" alt="bacola" />
                                                                </div><a
                                                                    href="/product/112/all-natural-italian-style-chicken-meatballs/"
                                                                    className="overlay-link" tabIndex="0"></a>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide" style={{ width: '1123px' }} data-slick-index="1"
                                                            aria-hidden="true" tabIndex="-1">
                                                            <div className="slider-item">
                                                                {/* <div className="content-wrapper">
                                                     <div className="content-header">
                                                        <div className="content-description">Exclusive Offer</div>
                                                        <div className="content-discount">-40% Off</div>
                                                     </div>
                                                     <div className="content-main">
                                                        <h3 className="entry-title">Quality Freshness Guaranteed!
                                                        </h3>
                                                        <div className="content-text">Only this week. Don’t miss...
                                                        </div>
                                                     </div>
                                                     <div className="content-footer"><span className="price-text">from
                                                        </span><span className="price">$14.35</span></div>
                                                        <a
                                                        href="/product/112/zevia-kidz-strawberry-lemonade-zero-calorie-soda/"
                                                        className="button button-secondary rounded"
                                                        tabIndex="-1">Shop Now <i
                                                           className="klbth-icon-right-arrow"></i></a>
                                                  </div> */}
                                                                <div className="image-wrapper"><img
                                                                    src="/images/space-monkey-banner.jpg" alt="bacola" />
                                                                </div><a
                                                                    href="/product/112/zevia-kidz-strawberry-lemonade-zero-calorie-soda/"
                                                                    className="overlay-link" tabIndex="-1"></a>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-cloned" style={{ width: '1123px' }}
                                                            data-slick-index="2" id="" aria-hidden="true" tabIndex="-1">
                                                            <div className="slider-item">
                                                                {/* <div className="content-wrapper">
                                                  <div className="content-header">
                                                     <div className="content-description">Exclusive Offer</div>
                                                     <div className="content-discount">-20% Off</div>
                                                  </div>
                                                  <div className="content-main">
                                                     <h3 className="entry-title">A different kind of grocery store
                                                     </h3>
                                                     <div className="content-text">Only this week. Don’t miss...
                                                     </div>
                                                  </div>
                                                  <div className="content-footer"><span className="price-text">from
                                                     </span><span className="price">$7.99</span></div><a
                                                     href="/product/112/all-natural-italian-style-chicken-meatballs/"
                                                     className="button button-secondary rounded"
                                                     tabIndex="-1">Shop Now <i
                                                        className="klbth-icon-right-arrow"></i></a>
                                               </div> */}
                                                                <div className="image-wrapper"><img
                                                                    src="/images/space-monkey-banner.jpg" alt="bacola" />
                                                                </div><a
                                                                    href="/product/112/all-natural-italian-style-chicken-meatballs/"
                                                                    className="overlay-link" tabIndex="-1"></a>
                                                            </div>
                                                        </div>
                                                        {/* <div className="slick-slide slick-cloned" style={{width: '1123px'}}
                                            data-slick-index="3" id="" aria-hidden="true" tabIndex="-1">
                                            <div className="slider-item">
                                               <div className="content-wrapper">
                                                  <div className="content-header">
                                                     <div className="content-description">Exclusive Offer</div>
                                                     <div className="content-discount">-40% Off</div>
                                                  </div>
                                                  <div className="content-main">
                                                     <h3 className="entry-title">Quality Freshness Guaranteed!
                                                     </h3>
                                                     <div className="content-text">Only this week. Don’t miss...
                                                     </div>
                                                  </div>
                                                  <div className="content-footer"><span className="price-text">from
                                                     </span><span className="price">$14.35</span></div><a
                                                     href="/product/112/zevia-kidz-strawberry-lemonade-zero-calorie-soda/"
                                                     className="button button-secondary rounded"
                                                     tabIndex="-1">Shop Now <i
                                                        className="klbth-icon-right-arrow"></i></a>
                                               </div>
                                               <div className="image-wrapper"><img
                                                     src="/images/bacola-banner-11.jpg" alt="bacola" />
                                               </div><a
                                                  href="/product/112/zevia-kidz-strawberry-lemonade-zero-calorie-soda/"
                                                  className="overlay-link" tabIndex="-1"></a>
                                            </div>
                                         </div> */}
                                                    </div>
                                                </div><button className="slick-next slick-arrow" aria-label="Next"
                                                    type="button" onClick={() => { if(bannerTranslate <= -1500 || (0-(4-1) * 500) > bannerTranslate){ setBannerTranslate(0)}else{setBannerTranslate(bannerTranslate - 500)}}} >Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner;