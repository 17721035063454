import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { retriveAddreses } from "../../services/CustomerService";
import Navigation from "./Navigation";

const Address = () => {
  const [billingAdd, setBillingAdd] = useState({});
  const [shippingAdd, setShippingAdd] = useState({});

  useEffect(() => {
    getAddresses();
  }, []);

  const getAddresses = async() => {
    await retriveAddreses().then((response) => {
      if(response.data){
        let data = response.data.data;
        
        let bill_add ={
          address: data.address,
          city: data.city,
          state_id: data.state_id,
          state_name: data.state.name,
          pincode: data.pincode,
        }

        let shipp_add = {};
        if(data.hasOwnProperty('addresses') && data.addresses.length > 0){
          let temp = data.addresses[0];
          shipp_add = {
            address: temp.address,
            city: temp.city,
            state_id: temp.state_id,
            pincode: temp.pincode,
            state_name: temp.state?.name,
            shipp_add_id: temp.id,
          }
        }
        
        setBillingAdd(bill_add);
        setShippingAdd(shipp_add);
      }
    }).catch((error) => {
      console.log("Error", error.response)
      if (error.response.status == 422)
        toast(error.response.data.error, { type: 'error' })
      else if (error.response.status == 500)
        toast(error.response.data.message, { type: 'error' })
      else
        toast("Something went wrong", { type: 'error' });
    });
  }

  return (
    <main id="main" className="site-primary">
      <div className="site-content">
        <div className="homepage-content">
          <div className="shop-content my-account-page">
            <div className="container">
              <nav className="woocommerce-breadcrumb"><ul><li><a href="#https://klbtheme.com/bacola">Home</a></li><li>My account</li></ul></nav>
              <div className="woocommerce"><div className="row content-wrapper sidebar-right">
                <div className="col-12 col-md-12 col-lg-12 content-primary">
                  <div className="my-account-wrapper"> {/* my-account-wrapper be closed in myaccount.php */}
                    <div className="my-account-navigation">
                      <div className="account-toggle-menu">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"><line x1="2.3" y1={12} x2="21.8" y2={12} /><line x1="2.3" y1={6} x2="21.8" y2={6} /><line x1="2.3" y1={18} x2="21.8" y2={18} /></svg>
                        Navigation
                      </div>{/* account-toggle-menu */}
                      <Navigation />
                    </div>
                    <div className="woocommerce-MyAccount-content">
                      <div className="woocommerce-notices-wrapper" />
                      <p>
                        The following addresses will be used on the checkout page by default.</p>
                      <div className="u-columns woocommerce-Addresses col2-set addresses">
                        <div className="u-column1 col-1 woocommerce-Address cst-50-w">
                          <header className="woocommerce-Address-title title">
                            <h3>Billing address</h3>
                            <Link
                              to={"/my-account/edit-address/billing?add=" + JSON.stringify(billingAdd)}
                              className="edit"
                            >
                              Edit
                            </Link>
                          </header>
                          <address>
                            { billingAdd.address} <br />
                            { billingAdd.city} <br />
                            { billingAdd.state_name} <br />
                            { billingAdd.pincode} <br />
                          </address>
                        </div>
                        <div className="u-column2 col-2 woocommerce-Address cst-50-w">
                          <header className="woocommerce-Address-title title">
                            <h3>Shipping address</h3>
                            <Link
                              to={"/my-account/edit-address/shipping?add=" + JSON.stringify(shippingAdd)}
                              className="edit"
                            >
                              Edit
                            </Link>
                          </header>
                          <address>
                            {
                            Object.keys(shippingAdd).length > 0 ? <>
                            {shippingAdd?.address} <br />
                            { shippingAdd?.city} <br />
                            { shippingAdd?.state_name} <br />
                            { shippingAdd?.pincode} <br /> </>
                            : 'No Address Available'}
                          </address>
                        </div>
                      </div>

                    </div>
                  </div> {/* my-account-wrapper closed in navigation.php */}
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>{/* homepage-content */}
      </div>{/* site-content */}
    </main>
  );
}

export default Address;