import { useState } from "react";
import { useEffect } from "react";
import { Link, Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { updateBillingAddress, updateShippingAddress } from "../../services/CustomerService";
import { getAllStates } from "../../services/StateService";
import Navigation from "./Navigation";

const EditAddress = () => {
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [address, setAddress] = useState(JSON.parse(searchParams.get("add")))
    const [states, setStates] = useState([])
    const [type,setType] = useState(params.type)
    const navigate = useNavigate();
    

    useEffect(() => {
        getStates();
    },[]);

    const getStates = async() => {
        await getAllStates().then((response) =>{
            setStates(response.data);
        }).catch((error)=>{

        });
    }

    const handleAddressChange = (e) =>{
        let {name, value} = e.target;
        setAddress({...address,[name]: value});
    }


    const handleSubmit = async() =>{
        if(validateInput()){
            delete address.state_name;

            if(type == 'billing'){
                await updateBillingAddress(address).then((response) => {
                    toast("Billing Address Updated Successfully.",{type: 'success'});
                }).catch((error) => {
                    console.log("Error", error);
                })
            }else{
                await updateShippingAddress(address).then((response) => {
                    toast("Shipping Address Updated Successfully.",{type: 'success'});
                }).catch((error) => {
                    console.log("Error", error);
                })
            }
        }
    }

    const validateInput = () => {
        if(!address.hasOwnProperty('address') ||  address.address == ''){
            toast("Please Enter Address.",{
                type:'error'
            });
            return false
        }
        if(!address.hasOwnProperty('city') || address.city == ''){
            toast("Please Enter City.",{
                type:'error'
            });
            return false
        }
        if(!address.hasOwnProperty('state_id') || address.state_id == ''){
            toast("Please Select State.",{
                type:'error'
            });
            return false
        }
        if(!address.hasOwnProperty('pincode') || address.pincode == ''){
            toast("Please Enter Pincode.",{
                type:'error'
            });
            return false
        }
        
        return true;
    }

    return (
        <main id="main" className="site-primary">
            <div className="site-content">
                <div className="homepage-content">
                    <div className="shop-content my-account-page">
                        <div className="container">
                            <nav className="woocommerce-breadcrumb">
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/my-account">My account</Link>
                                    </li>
                                    <li>Addresses</li>
                                </ul>
                            </nav>
                            <div className="woocommerce">
                                <div className="row content-wrapper sidebar-right">
                                    <div className="col-12 col-md-12 col-lg-12 content-primary">
                                        <div className="my-account-wrapper">
                                            {" "}
                                            {/* my-account-wrapper be closed in myaccount.php */}
                                            <div className="my-account-navigation">
                                                <div className="account-toggle-menu">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="1.6"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        <line x1="2.3" y1={12} x2="21.8" y2={12} />
                                                        <line x1="2.3" y1={6} x2="21.8" y2={6} />
                                                        <line x1="2.3" y1={18} x2="21.8" y2={18} />
                                                    </svg>
                                                    Navigation{" "}
                                                </div>
                                                {/* account-toggle-menu */}
                                                <Navigation />
                                            </div>
                                            <div className="woocommerce-MyAccount-content">
                                                <div className="woocommerce-notices-wrapper" />
                                                <form method="post">
                                                    <h3> { type == "shipping" ? 'Shipping' : 'Billing'} address</h3>
                                                    <div className="woocommerce-address-fields">
                                                        <div className="woocommerce-address-fields__field-wrapper">
                                                            {/* <p
                                                                className="form-row form-row-first validate-required"
                                                                id="shipping_first_name_field"
                                                                data-priority={10}
                                                            >
                                                                <label htmlFor="shipping_first_name" className="">
                                                                    First name&nbsp;
                                                                    <abbr className="required" title="required">
                                                                        *
                                                                    </abbr>
                                                                </label>
                                                                <span className="woocommerce-input-wrapper">
                                                                    <input
                                                                        type="text"
                                                                        className="input-text "
                                                                        name="shipping_first_name"
                                                                        id="shipping_first_name"
                                                                        placeholder=""
                                                                        defaultValue="Naida"
                                                                        autoComplete="given-name"
                                                                    />
                                                                </span>
                                                            </p>
                                                            <p
                                                                className="form-row form-row-last validate-required"
                                                                id="shipping_last_name_field"
                                                                data-priority={20}
                                                            >
                                                                <label htmlFor="shipping_last_name" className="">
                                                                    Last name&nbsp;
                                                                    <abbr className="required" title="required">
                                                                        *
                                                                    </abbr>
                                                                </label>
                                                                <span className="woocommerce-input-wrapper">
                                                                    <input
                                                                        type="text"
                                                                        className="input-text "
                                                                        name="shipping_last_name"
                                                                        id="shipping_last_name"
                                                                        placeholder=""
                                                                        defaultValue="Workman"
                                                                        autoComplete="family-name"
                                                                    />
                                                                </span>
                                                            </p>
                                                            <p
                                                                className="form-row form-row-wide"
                                                                id="shipping_company_field"
                                                                data-priority={30}
                                                            >
                                                                <label htmlFor="shipping_company" className="">
                                                                    Company name&nbsp;
                                                                    <span className="optional">(optional)</span>
                                                                </label>
                                                                <span className="woocommerce-input-wrapper">
                                                                    <input
                                                                        type="text"
                                                                        className="input-text "
                                                                        name="shipping_company"
                                                                        id="shipping_company"
                                                                        placeholder=""
                                                                        defaultValue="Nixon Stokes Trading"
                                                                        autoComplete="organization"
                                                                    />
                                                                </span>
                                                            </p>
                                                            <p
                                                                className="form-row form-row-wide address-field update_totals_on_change validate-required"
                                                                id="shipping_country_field"
                                                                data-priority={40}
                                                            >
                                                                <label htmlFor="shipping_country" className="">
                                                                    Country / Region&nbsp;
                                                                    <abbr className="required" title="required">
                                                                        *
                                                                    </abbr>
                                                                </label>
                                                                <span className="woocommerce-input-wrapper">
                                                                    <select
                                                                        name="shipping_country"
                                                                        id="shipping_country"
                                                                        className="country_to_state country_select select2-hidden-accessible"
                                                                        autoComplete="country"
                                                                        data-placeholder="Select a country / region…"
                                                                        data-label="Country / Region"
                                                                        tabIndex={-1}
                                                                        aria-hidden="true"
                                                                    >
                                                                        <option selected value="">
                                                                            Select a country / region…
                                                                        </option>
                                                                        <option value="AF">Afghanistan</option>
                                                                        <option value="AX">Åland Islands</option>
                                                                        <option value="AL">Albania</option>
                                                                        <option value="DZ">Algeria</option>
                                                                        <option value="AS">American Samoa</option>
                                                                        <option value="AD">Andorra</option>
                                                                        <option value="AO">Angola</option>
                                                                        <option value="AI">Anguilla</option>
                                                                        <option value="AQ">Antarctica</option>
                                                                        <option value="AG">Antigua and Barbuda</option>
                                                                        <option value="AR">Argentina</option>
                                                                        <option value="AM">Armenia</option>
                                                                        <option value="AW">Aruba</option>
                                                                        <option value="AU">Australia</option>
                                                                        <option value="AT">Austria</option>
                                                                        <option value="AZ">Azerbaijan</option>
                                                                        <option value="BS">Bahamas</option>
                                                                        <option value="BH">Bahrain</option>
                                                                        <option value="BD">Bangladesh</option>
                                                                        <option value="BB">Barbados</option>
                                                                        <option value="BY">Belarus</option>
                                                                        <option value="PW">Belau</option>
                                                                        <option value="BE">Belgium</option>
                                                                        <option value="BZ">Belize</option>
                                                                        <option value="BJ">Benin</option>
                                                                        <option value="BM">Bermuda</option>
                                                                        <option value="BT">Bhutan</option>
                                                                        <option value="BO">Bolivia</option>
                                                                        <option value="BQ">
                                                                            Bonaire, Saint Eustatius and Saba
                                                                        </option>
                                                                        <option value="BA">
                                                                            Bosnia and Herzegovina
                                                                        </option>
                                                                        <option value="BW">Botswana</option>
                                                                        <option value="BV">Bouvet Island</option>
                                                                        <option value="BR">Brazil</option>
                                                                        <option value="IO">
                                                                            British Indian Ocean Territory
                                                                        </option>
                                                                        <option value="BN">Brunei</option>
                                                                        <option value="BG">Bulgaria</option>
                                                                        <option value="BF">Burkina Faso</option>
                                                                        <option value="BI">Burundi</option>
                                                                        <option value="KH">Cambodia</option>
                                                                        <option value="CM">Cameroon</option>
                                                                        <option value="CA">Canada</option>
                                                                        <option value="CV">Cape Verde</option>
                                                                        <option value="KY">Cayman Islands</option>
                                                                        <option value="CF">
                                                                            Central African Republic
                                                                        </option>
                                                                        <option value="TD">Chad</option>
                                                                        <option value="CL">Chile</option>
                                                                        <option value="CN">China</option>
                                                                        <option value="CX">Christmas Island</option>
                                                                        <option value="CC">
                                                                            Cocos (Keeling) Islands
                                                                        </option>
                                                                        <option value="CO">Colombia</option>
                                                                        <option value="KM">Comoros</option>
                                                                        <option value="CG">Congo (Brazzaville)</option>
                                                                        <option value="CD">Congo (Kinshasa)</option>
                                                                        <option value="CK">Cook Islands</option>
                                                                        <option value="CR">Costa Rica</option>
                                                                        <option value="HR">Croatia</option>
                                                                        <option value="CU">Cuba</option>
                                                                        <option value="CW">Curaçao</option>
                                                                        <option value="CY">Cyprus</option>
                                                                        <option value="CZ">Czech Republic</option>
                                                                        <option value="DK">Denmark</option>
                                                                        <option value="DJ">Djibouti</option>
                                                                        <option value="DM">Dominica</option>
                                                                        <option value="DO">Dominican Republic</option>
                                                                        <option value="EC">Ecuador</option>
                                                                        <option value="EG">Egypt</option>
                                                                        <option value="SV">El Salvador</option>
                                                                        <option value="GQ">Equatorial Guinea</option>
                                                                        <option value="ER">Eritrea</option>
                                                                        <option value="EE">Estonia</option>
                                                                        <option value="SZ">Eswatini</option>
                                                                        <option value="ET">Ethiopia</option>
                                                                        <option value="FK">Falkland Islands</option>
                                                                        <option value="FO">Faroe Islands</option>
                                                                        <option value="FJ">Fiji</option>
                                                                        <option value="FI">Finland</option>
                                                                        <option value="FR">France</option>
                                                                        <option value="GF">French Guiana</option>
                                                                        <option value="PF">French Polynesia</option>
                                                                        <option value="TF">
                                                                            French Southern Territories
                                                                        </option>
                                                                        <option value="GA">Gabon</option>
                                                                        <option value="GM">Gambia</option>
                                                                        <option value="GE">Georgia</option>
                                                                        <option value="DE">Germany</option>
                                                                        <option value="GH">Ghana</option>
                                                                        <option value="GI">Gibraltar</option>
                                                                        <option value="GR">Greece</option>
                                                                        <option value="GL">Greenland</option>
                                                                        <option value="GD">Grenada</option>
                                                                        <option value="GP">Guadeloupe</option>
                                                                        <option value="GU">Guam</option>
                                                                        <option value="GT">Guatemala</option>
                                                                        <option value="GG">Guernsey</option>
                                                                        <option value="GN">Guinea</option>
                                                                        <option value="GW">Guinea-Bissau</option>
                                                                        <option value="GY">Guyana</option>
                                                                        <option value="HT">Haiti</option>
                                                                        <option value="HM">
                                                                            Heard Island and McDonald Islands
                                                                        </option>
                                                                        <option value="HN">Honduras</option>
                                                                        <option value="HK">Hong Kong</option>
                                                                        <option value="HU">Hungary</option>
                                                                        <option value="IS">Iceland</option>
                                                                        <option value="IN">India</option>
                                                                        <option value="ID">Indonesia</option>
                                                                        <option value="IR">Iran</option>
                                                                        <option value="IQ">Iraq</option>
                                                                        <option value="IE">Ireland</option>
                                                                        <option value="IM">Isle of Man</option>
                                                                        <option value="IL">Israel</option>
                                                                        <option value="IT">Italy</option>
                                                                        <option value="CI">Ivory Coast</option>
                                                                        <option value="JM">Jamaica</option>
                                                                        <option value="JP">Japan</option>
                                                                        <option value="JE">Jersey</option>
                                                                        <option value="JO">Jordan</option>
                                                                        <option value="KZ">Kazakhstan</option>
                                                                        <option value="KE">Kenya</option>
                                                                        <option value="KI">Kiribati</option>
                                                                        <option value="KW">Kuwait</option>
                                                                        <option value="KG">Kyrgyzstan</option>
                                                                        <option value="LA">Laos</option>
                                                                        <option value="LV">Latvia</option>
                                                                        <option value="LB">Lebanon</option>
                                                                        <option value="LS">Lesotho</option>
                                                                        <option value="LR">Liberia</option>
                                                                        <option value="LY">Libya</option>
                                                                        <option value="LI">Liechtenstein</option>
                                                                        <option value="LT">Lithuania</option>
                                                                        <option value="LU">Luxembourg</option>
                                                                        <option value="MO">Macao</option>
                                                                        <option value="MG">Madagascar</option>
                                                                        <option value="MW">Malawi</option>
                                                                        <option value="MY">Malaysia</option>
                                                                        <option value="MV">Maldives</option>
                                                                        <option value="ML">Mali</option>
                                                                        <option value="MT">Malta</option>
                                                                        <option value="MH">Marshall Islands</option>
                                                                        <option value="MQ">Martinique</option>
                                                                        <option value="MR">Mauritania</option>
                                                                        <option value="MU">Mauritius</option>
                                                                        <option value="YT">Mayotte</option>
                                                                        <option value="MX">Mexico</option>
                                                                        <option value="FM">Micronesia</option>
                                                                        <option value="MD">Moldova</option>
                                                                        <option value="MC">Monaco</option>
                                                                        <option value="MN">Mongolia</option>
                                                                        <option value="ME">Montenegro</option>
                                                                        <option value="MS">Montserrat</option>
                                                                        <option value="MA">Morocco</option>
                                                                        <option value="MZ">Mozambique</option>
                                                                        <option value="MM">Myanmar</option>
                                                                        <option value="NA">Namibia</option>
                                                                        <option value="NR">Nauru</option>
                                                                        <option value="NP">Nepal</option>
                                                                        <option value="NL">Netherlands</option>
                                                                        <option value="NC">New Caledonia</option>
                                                                        <option value="NZ">New Zealand</option>
                                                                        <option value="NI">Nicaragua</option>
                                                                        <option value="NE">Niger</option>
                                                                        <option value="NG">Nigeria</option>
                                                                        <option value="NU">Niue</option>
                                                                        <option value="NF">Norfolk Island</option>
                                                                        <option value="KP">North Korea</option>
                                                                        <option value="MK">North Macedonia</option>
                                                                        <option value="MP">
                                                                            Northern Mariana Islands
                                                                        </option>
                                                                        <option value="NO">Norway</option>
                                                                        <option value="OM">Oman</option>
                                                                        <option value="PK">Pakistan</option>
                                                                        <option value="PS">
                                                                            Palestinian Territory
                                                                        </option>
                                                                        <option value="PA">Panama</option>
                                                                        <option value="PG">Papua New Guinea</option>
                                                                        <option value="PY">Paraguay</option>
                                                                        <option value="PE">Peru</option>
                                                                        <option value="PH">Philippines</option>
                                                                        <option value="PN">Pitcairn</option>
                                                                        <option value="PL">Poland</option>
                                                                        <option value="PT">Portugal</option>
                                                                        <option value="PR">Puerto Rico</option>
                                                                        <option value="QA">Qatar</option>
                                                                        <option value="RE">Reunion</option>
                                                                        <option value="RO">Romania</option>
                                                                        <option value="RU">Russia</option>
                                                                        <option value="RW">Rwanda</option>
                                                                        <option value="ST">
                                                                            São Tomé and Príncipe
                                                                        </option>
                                                                        <option value="BL">Saint Barthélemy</option>
                                                                        <option value="SH">Saint Helena</option>
                                                                        <option value="KN">
                                                                            Saint Kitts and Nevis
                                                                        </option>
                                                                        <option value="LC">Saint Lucia</option>
                                                                        <option value="SX">
                                                                            Saint Martin (Dutch part)
                                                                        </option>
                                                                        <option value="MF">
                                                                            Saint Martin (French part)
                                                                        </option>
                                                                        <option value="PM">
                                                                            Saint Pierre and Miquelon
                                                                        </option>
                                                                        <option value="VC">
                                                                            Saint Vincent and the Grenadines
                                                                        </option>
                                                                        <option value="WS">Samoa</option>
                                                                        <option value="SM">San Marino</option>
                                                                        <option value="SA">Saudi Arabia</option>
                                                                        <option value="SN">Senegal</option>
                                                                        <option value="RS">Serbia</option>
                                                                        <option value="SC">Seychelles</option>
                                                                        <option value="SL">Sierra Leone</option>
                                                                        <option value="SG">Singapore</option>
                                                                        <option value="SK">Slovakia</option>
                                                                        <option value="SI">Slovenia</option>
                                                                        <option value="SB">Solomon Islands</option>
                                                                        <option value="SO">Somalia</option>
                                                                        <option value="ZA">South Africa</option>
                                                                        <option value="GS">
                                                                            South Georgia/Sandwich Islands
                                                                        </option>
                                                                        <option value="KR">South Korea</option>
                                                                        <option value="SS">South Sudan</option>
                                                                        <option value="ES">Spain</option>
                                                                        <option value="LK">Sri Lanka</option>
                                                                        <option value="SD">Sudan</option>
                                                                        <option value="SR">Suriname</option>
                                                                        <option value="SJ">
                                                                            Svalbard and Jan Mayen
                                                                        </option>
                                                                        <option value="SE">Sweden</option>
                                                                        <option value="CH">Switzerland</option>
                                                                        <option value="SY">Syria</option>
                                                                        <option value="TW">Taiwan</option>
                                                                        <option value="TJ">Tajikistan</option>
                                                                        <option value="TZ">Tanzania</option>
                                                                        <option value="TH">Thailand</option>
                                                                        <option value="TL">Timor-Leste</option>
                                                                        <option value="TG">Togo</option>
                                                                        <option value="TK">Tokelau</option>
                                                                        <option value="TO">Tonga</option>
                                                                        <option value="TT">Trinidad and Tobago</option>
                                                                        <option value="TN">Tunisia</option>
                                                                        <option value="TR">Turkey</option>
                                                                        <option value="TM">Turkmenistan</option>
                                                                        <option value="TC">
                                                                            Turks and Caicos Islands
                                                                        </option>
                                                                        <option value="TV">Tuvalu</option>
                                                                        <option value="UG">Uganda</option>
                                                                        <option value="UA">Ukraine</option>
                                                                        <option value="AE">United Arab Emirates</option>
                                                                        <option value="GB">United Kingdom (UK)</option>
                                                                        <option value="US">United States (US)</option>
                                                                        <option value="UM">
                                                                            United States (US) Minor Outlying Islands
                                                                        </option>
                                                                        <option value="UY">Uruguay</option>
                                                                        <option value="UZ">Uzbekistan</option>
                                                                        <option value="VU">Vanuatu</option>
                                                                        <option value="VA">Vatican</option>
                                                                        <option value="VE" selected="selected">
                                                                            Venezuela
                                                                        </option>
                                                                        <option value="VN">Vietnam</option>
                                                                        <option value="VG">
                                                                            Virgin Islands (British)
                                                                        </option>
                                                                        <option value="VI">Virgin Islands (US)</option>
                                                                        <option value="WF">Wallis and Futuna</option>
                                                                        <option value="EH">Western Sahara</option>
                                                                        <option value="YE">Yemen</option>
                                                                        <option value="ZM">Zambia</option>
                                                                        <option value="ZW">Zimbabwe</option>
                                                                    </select>
                                                                    <span
                                                                        className="select2 select2-container select2-container--default"
                                                                        dir="ltr"
                                                                        style={{ width: "100%" }}
                                                                    >
                                                                        <span className="selection">
                                                                            <span
                                                                                className="select2-selection select2-selection--single"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false"
                                                                                tabIndex={0}
                                                                                aria-label="Country / Region"
                                                                                role="combobox"
                                                                            >
                                                                                <span
                                                                                    className="select2-selection__rendered"
                                                                                    id="select2-shipping_country-container"
                                                                                    role="textbox"
                                                                                    aria-readonly="true"
                                                                                    title="Venezuela"
                                                                                >
                                                                                    Venezuela
                                                                                </span>
                                                                                <span
                                                                                    className="select2-selection__arrow"
                                                                                    role="presentation"
                                                                                >
                                                                                    <b role="presentation" />
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                        <span
                                                                            className="dropdown-wrapper"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </span>
                                                                    <noscript>
                                                                        &lt;button type="submit"
                                                                        name="woocommerce_checkout_update_totals"
                                                                        value="Update country / region"&gt;Update
                                                                        country / region&lt;/button&gt;
                                                                    </noscript>
                                                                </span>
                                                            </p> */}
                                                            <p
                                                                className="form-row address-field validate-required form-row-wide"
                                                                id="shipping_address_1_field"
                                                                data-priority={50}
                                                            >
                                                                <label htmlFor="shipping_address_1" className="">
                                                                    Address&nbsp;
                                                                    <abbr className="required" title="required">
                                                                        *
                                                                    </abbr>
                                                                </label>
                                                                <span className="woocommerce-input-wrapper">
                                                                    <textarea
                                                                        type="text"
                                                                        className="input-text"
                                                                        style={{height:'100%'}}
                                                                        name="address"
                                                                        rows={2}
                                                                        cols={40}
                                                                        id="shipping_address_1"
                                                                        placeholder="Enter Full Address"
                                                                        value={address.address}    
                                                                        onChange={(e) => handleAddressChange(e)}
                                                                        autoComplete="address-line1"
                                                                        data-placeholder="House number and street name"
                                                                    > </textarea>
                                                                </span>
                                                            </p>
                                                            <p
                                                                className="form-row address-field form-row-wide"
                                                                id="shipping_address_2_field"
                                                                data-priority={60}
                                                            >
                                                                <label
                                                                    htmlFor="shipping_address_2"
                                                                    className="screen-reader-text"
                                                                >
                                                                    Apartment, suite, unit, etc.&nbsp;
                                                                    <span className="optional">(optional)</span>
                                                                </label>
                                                                <span className="woocommerce-input-wrapper">
                                                                   {/*  <input
                                                                        type="text"
                                                                        className="input-text "
                                                                        name="shipping_address_2"
                                                                        id="shipping_address_2"
                                                                        placeholder="Apartment, suite, unit, etc. (optional)"
                                                                        defaultValue="Duis cumque mollit d"
                                                                        autoComplete="address-line2"
                                                                        data-placeholder="Apartment, suite, unit, etc. (optional)"
                                                                    /> */}
                                                                </span>
                                                            </p>
                                                            <p
                                                                className="form-row address-field validate-required form-row-wide"
                                                                id="shipping_city_field"
                                                                data-priority={70}
                                                                data-o_class="form-row form-row-wide address-field validate-required"
                                                            >
                                                                <label htmlFor="shipping_city" className="">
                                                                    Town / City&nbsp;
                                                                    <abbr className="required" title="required">
                                                                        *
                                                                    </abbr>
                                                                </label>
                                                                <span className="woocommerce-input-wrapper">
                                                                    <input
                                                                        type="text"
                                                                        className="input-text "
                                                                        name="city"
                                                                        id="shipping_city"
                                                                        placeholder=""
                                                                        value={address.city}
                                                                        autoComplete="address-level2"
                                                                        onChange={(e) => handleAddressChange(e)}
                                                                    />
                                                                </span>
                                                            </p>
                                                            <p
                                                                className="form-row address-field validate-required validate-state form-row-wide"
                                                                id="shipping_state_field"
                                                                data-priority={80}
                                                                data-o_class="form-row form-row-wide address-field validate-required validate-state"
                                                            >
                                                                <label htmlFor="shipping_state" className="">
                                                                    State&nbsp;
                                                                    <abbr className="required" title="required">
                                                                        *
                                                                    </abbr>
                                                                </label>
                                                                <span className="woocommerce-input-wrapper">
                                                                    <select
                                                                        name="state_id"
                                                                        className="select"
                                                                        autoComplete="address-level1"
                                                                        value={address.state_id}
                                                                        onChange={(e) => handleAddressChange(e)}
                                                                    >
                                                                        <option value="">Select an option…</option>
                                                                        {
                                                                            states.map((state,index)=>{
                                                                                return <option key={index} value={state.id}> {state.name} </option>
                                                                            })
                                                                        } 
                                                                    </select>
                                                                   {/*  <span
                                                                        className="select2 select2-container select2-container--default"
                                                                        dir="ltr"
                                                                        style={{ width: "100%" }}
                                                                    >
                                                                        <span className="selection">
                                                                            <span
                                                                                className="select2-selection select2-selection--single"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false"
                                                                                tabIndex={0}
                                                                                aria-label="State / County"
                                                                                role="combobox"
                                                                            >
                                                                                <span
                                                                                    className="select2-selection__rendered"
                                                                                    id="select2-shipping_state-container"
                                                                                    role="textbox"
                                                                                    aria-readonly="true"
                                                                                    title="Aragua"
                                                                                >
                                                                                    Aragua
                                                                                </span>
                                                                                <span
                                                                                    className="select2-selection__arrow"
                                                                                    role="presentation"
                                                                                >
                                                                                    <b role="presentation" />
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                        <span
                                                                            className="dropdown-wrapper"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </span> */}
                                                                </span>
                                                            </p>

                                                            <p
                                                                className="form-row address-field validate-required form-row-wide"
                                                                id="shipping_city_field"
                                                                data-priority={70}
                                                                data-o_class="form-row form-row-wide address-field validate-required"
                                                            >
                                                                <label htmlFor="shipping_city" className="">
                                                                    Pincode&nbsp;
                                                                    <abbr className="required" title="required">
                                                                        *
                                                                    </abbr>
                                                                </label>
                                                                <span className="woocommerce-input-wrapper">
                                                                    <input
                                                                        type="text"
                                                                        className="input-text "
                                                                        name="pincode"
                                                                        id="shipping_city"
                                                                        placeholder=""
                                                                        value={address.pincode}
                                                                        autoComplete="address-level2"
                                                                        onChange={(e) => handleAddressChange(e)}
                                                                    />
                                                                </span>
                                                            </p>
                                                           {/*  <p
                                                                className="form-row address-field validate-required validate-postcode form-row-wide"
                                                                id="shipping_postcode_field"
                                                                data-priority={90}
                                                                data-o_class="form-row form-row-wide address-field validate-required validate-postcode"
                                                            >
                                                                <label htmlFor="shipping_postcode" className="">
                                                                    Postcode / ZIP&nbsp;
                                                                    <abbr className="required" title="required">
                                                                        *
                                                                    </abbr>
                                                                </label>
                                                                <span className="woocommerce-input-wrapper">
                                                                    <input
                                                                        type="text"
                                                                        className="input-text "
                                                                        name="shipping_postcode"
                                                                        id="shipping_postcode"
                                                                        placeholder=""
                                                                        defaultValue={59483}
                                                                        autoComplete="postal-code"
                                                                    />
                                                                </span>
                                                            </p> */}
                                                        </div>
                                                        <p>
                                                            <button
                                                                type="button"
                                                                className="button"
                                                                name="save_address"
                                                                value="Save address"
                                                                onClick={()=> handleSubmit()}
                                                            >
                                                                Save address
                                                            </button>
                                                            
                                                            <button
                                                                type="button"
                                                                className="button ml-2"
                                                                name="save_address"
                                                                onClick={() => navigate('/my-account/addresses')}
                                                            >
                                                                Back
                                                            </button>
                                                            
                                                        </p>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>{" "}
                                        {/* my-account-wrapper closed in navigation.php */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* homepage-content */}
            </div>
            {/* site-content */}
        </main>

    );
}

export default EditAddress;