import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { confirmOrder } from "../../services/OrderService";

const OrderConfirm = () => {
    const [state,setState] = useState('Pending');
    const params = useParams();
    useEffect(async()=>{
        setState('Inprogress');
        
        await confirmOrder(params.sales_id).then((response) => {
            setState('Success');
          }).catch((error) => {
            setState('Failed');
          })
    },[]);

    return (
        <main id="main" className="site-primary">
            <div className="site-content">
                <div className="homepage-content">
                    <div
                        data-elementor-type="wp-page"
                        data-elementor-id={3}
                        className="elementor elementor-3 privacy-policy"
                    >
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-6430715 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id={6430715}
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-95c4fd1"
                                    data-id="95c4fd1"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-9cf14cd elementor-widget elementor-widget-heading"
                                            data-id="9cf14cd"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <h2 className="elementor-heading-title elementor-size-default">
                                                Order Confirmation
                                                </h2>{" "}
                                            </div>
                                            <p>Order confirmation status: <strong>{state}</strong> </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* homepage-content */}
            </div>
            {/* site-content */}
        </main>

    );
}

export default OrderConfirm;