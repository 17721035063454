import StoreNotice from "../../common/StoreNotice";
import Canvas from "../Canvas/Canvas";
import CanvasFooter from "../Canvas/CanvasFooter";
import Location from "../Canvas/Location";
import Navbar from "../Canvas/Navbar";
import HeaderMain from "./HeaderMain";
import HeaderMobileNavbar from "./HeaderMobileNavbar";
import HeaderNavbar from "./HeaderNavbar";
import HeaderTop from "./HeaderTop";

const Header = () => {
   return (
      <>
         <Canvas />

         <StoreNotice />

         <header id="masthead" className="site-header desktop-shadow-disable mobile-shadow-enable mobile-nav-enable" itemScope="itemscope" itemType="http://schema.org/WPHeader">


            <HeaderTop />

            <HeaderMain />

            <HeaderNavbar />

            <HeaderMobileNavbar />

         </header>
      </>
   );
}

export default Header;