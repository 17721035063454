const CanvasFooter = () =>{
    return (
        <div className="canvas-footer">
            <div className="site-copyright">
               Copyright 2022 © Kriaa Wholesale. All rights reserved. Powered by N2N Solution. </div>
            <nav className="canvas-menu canvas-secondary select-language vertical">
               <ul id="menu-top-right" className="menu">
                  <li id="menu-item-1832"
                     className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1832">
                     <a href="#/home-3/#">English</a>
                     <ul className="sub-menu">
                        <li id="menu-item-1838"
                           className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1838"><a
                              href="#/home-3/#">English</a></li>
                        <li id="menu-item-1833"
                           className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1833"><a
                              href="#/home-3/#">Spanish</a></li>
                        <li id="menu-item-1834"
                           className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1834"><a
                              href="#/home-3/#">German</a></li>
                     </ul>
                     <span className="menu-dropdown"><i className="klbth-icon-down-open-big"></i></span>
                  </li>
                  <li id="menu-item-1835"
                     className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1835">
                     <a href="#/home-3/#">USD</a>
                     <ul className="sub-menu">
                        <li id="menu-item-1839"
                           className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1839"><a
                              href="#/home-3/#">USD</a></li>
                        <li id="menu-item-1836"
                           className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1836"><a
                              href="#/home-3/#">INR</a></li>
                        <li id="menu-item-1837"
                           className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1837"><a
                              href="#/home-3/#">GBP</a></li>
                     </ul>
                     <span className="menu-dropdown"><i className="klbth-icon-down-open-big"></i></span>
                  </li>
               </ul>
            </nav>
         </div>
    );
}


export default CanvasFooter;