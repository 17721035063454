import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { lostPassword, resetPassword } from "../../services/AuthService";
import { randomString } from "../../utils/constant";

const ResetPassword = () => {
    const [state, setState] = useState({
        email: '',
        password: '',
        password_confirmation: ''
    });
    const [isValid, setIsValid] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const email = urlParams.get('email');

        console.log("my Params", token, email, urlParams);
        let storedToken = localStorage.getItem('pass-reset-token');
        if(storedToken === token){
            setIsValid(true);
            setState({...state,['email']: email});
        }else{
            setIsValid(false);
        }
    },[]);

    const handlePasswordChange = (e) => {
        let {name, value} = e.target;
        setState({...state, [name]: value});
    }

    const handleSubmit = async () => {
        if(validateInput()){
            await resetPassword(state).then((response) => {
                localStorage.removeItem('pass-reset-token');
                toast('Password Reset Sucessfully.', { type: 'success' })
                navigate('/login');
            }).catch((error) => {
                console.log("Error", error.response)
                if (error.response.status == 422)
                    toast(error.response.data.error, { type: 'error' })
                else if (error.response.status == 500)
                    toast(error.response.data.message, { type: 'error' })
                else
                    toast("Something went wrong", { type: 'error' });
            });
        }
    }

    const validateInput = () => {
       
        if(state.password == ''){
            toast("Please enter password.",{
                type:'error'
            });
            return false
        }
        
        if(state.password_confirmation == ''){
            toast("Please enter confirm password.",{
                type:'error'
            });
            return false
        }

        if(state.password_confirmation !== state.password){
            toast("Password and confirm password do not match.",{
                type:'error'
            });
            return false
        }
        return true;
    }

    return (
        <main id="main" className="site-primary">
            <div className="site-content">
                <div className="homepage-content">
                    <div className="shop-content my-account-page">
                        <div className="container">
                            <nav className="woocommerce-breadcrumb">
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link href="/my-account">My account</Link>
                                    </li>
                                    <li>Reset Password</li>
                                </ul>
                            </nav>
                            {
                                (isValid === true) ?
                            
                            <div className="woocommerce">
                                <div className="woocommerce-notices-wrapper" />
                                <form
                                    className="woocommerce-ResetPassword lost_reset_password">
                                    <p>
                                        Please reset your password here.
                                    </p>
                                    <p className="woocommerce-form-row woocommerce-form-row--first form-row form-row-first">
                                        <label htmlFor="user_login">New Password</label>
                                        <input
                                            className="woocommerce-Input woocommerce-Input--text input-text"
                                            type="password"
                                            name="password"
                                            id="user_login"
                                            autoComplete="off"
                                            value={state.password}
                                            onChange={(e) => handlePasswordChange(e)}
                                        />
                                    </p>
                                    
                                    <p className="woocommerce-form-row woocommerce-form-row--first form-row form-row-first">
                                        <label htmlFor="user_login">Confirm Password</label>
                                        <input
                                            className="woocommerce-Input woocommerce-Input--text input-text"
                                            type="password"
                                            name="password_confirmation"
                                            id="user_login"
                                            autoComplete="off"
                                            value={state.password_confirmation}
                                            onChange={(e) => handlePasswordChange(e)}
                                        />
                                    </p>

                                    <p className="woocommerce-form-row form-row">
                                        <button
                                            type="button"
                                            className="woocommerce-Button button wp-element-button"
                                            value="Reset password"
                                            onClick={() => handleSubmit()}
                                        >
                                            Reset password
                                        </button>
                                    </p>

                                </form>
                            </div>
                            :null
                            }
                            {
                                (isValid === false) ?
                                <div>
                                    <h4 style={{textAlign:'center', color:'#cd1111'}}>Can't proceed, Reset password process tempered</h4>
                                </div>
                                :null
                            }
                        </div>
                    </div>
                </div>
                {/* homepage-content */}
            </div>
            {/* site-content */}
        </main>

    );
}


export default ResetPassword;