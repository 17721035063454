import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { Routes, Route, Navigate } from "react-router-dom"
import routes from "../routes/route.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Provider from "../utils/AppProvider";
import { useState } from "react";

let is_authenticated = '';
const Index = () => {
    const [flag,setFlag] = useState(0);
    const location = useLocation()
    const navigate = useNavigate()
    useEffect(async () => {
        is_authenticated = await localStorage.getItem('is_authenticated');
        
        if (is_authenticated == 1 && (location.pathname == '/login' || location.pathname == '/register')) {
            navigate('/');
        }
        return ()=>{
            
        }
    });

    return (
        <>
            <Provider>
                <Header></Header>
                <Routes>
                    {
                        routes.map((route, i) => {
                            if (location.pathname == route.path && route.private === true) {
                                if (localStorage.getItem('is_authenticated')) {
                                    return <Route exact path={route.path} key={i} element={<route.page.component />}> </Route>
                                } else {
                                    <Navigate to="/login" />
                                }
                            } else {
                                return <Route exact path={route.path} key={i} element={<route.page.component />}> </Route>
                            }
                        })
                    }
                    <Route path="*" render={() => <h1>No Routes Match</h1>} > </Route>
                </Routes>
                <Footer></Footer>
            </Provider>
        </>
    );
}

export default Index;