const FooterContacts = () =>{
    return (
        <div className="footer-contacts">
                <div className="container">

                    <div className="column column-left">
                        <div className="site-phone">
                            <div className="phone-icon"><i className="klbth-icon-phone-call"></i></div>
                            <div className="phone-detail">
                                <h4 className="entry-title">8 800 555-55</h4>
                                <span>Working 8:00 - 22:00</span>
                            </div>
                        </div>
                    </div>

                    <div className="column column-right">
                        <div className="site-mobile-app">
                            <div className="app-content">
                                <h6 className="entry-title">Download App on Mobile :</h6>
                                <span>15% discount on your first purchase</span>
                            </div>
                            <div className="app-buttons">
                                <a href="#https://play.google.com/store" className="google-play">
                                    <img src="/images/google-play.png" alt="app" />
                                </a>
                                <a href="#https://www.apple.com/app-store/" className="google-play">
                                    <img src="/images/app-store.png" alt="app" />
                                </a>
                            </div>
                        </div>

                        <div className="site-social">
                            <ul>
                                <li><a href="#https://www.facebook.com/" target="_blank"><i className="klbth-icon-facebook"></i></a>
                                </li>
                                <li><a href="#https://twitter.com/" target="_blank"><i className="klbth-icon-twitter"></i></a></li>
                                <li><a href="#https://www.instagram.com/" target="_blank"><i className="klbth-icon-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>        
    );
}


export default FooterContacts;