const PrivacyPolicy = () => {
    return (
        <main id="main" className="site-primary">
            <div className="site-content">
                <div className="homepage-content">
                    <div
                        data-elementor-type="wp-page"
                        data-elementor-id={3}
                        className="elementor elementor-3 privacy-policy"
                    >
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-6430715 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id={6430715}
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-95c4fd1"
                                    data-id="95c4fd1"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-9cf14cd elementor-widget elementor-widget-heading"
                                            data-id="9cf14cd"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <h2 className="elementor-heading-title elementor-size-default">
                                                Privacy Policy
                                                </h2>{" "}
                                            </div>
                                            <p className="sub-text">
                                                Your privacy is not something Infinity Group Inc. (“Infinity Group,” “we,” or “our”) takes lightly, as we are committed to building solid business relationships, which require earning and maintaining trust. We wish to communicate what forms of information we collect from and about you when you use this website, how this information is used and protected, and how you are able to control its use. By using this website, you are agreeing to be bound by this Privacy Policy, without qualification or limitation. This Privacy Policy is incorporated into and made a part of the website. The information contained within this Privacy Policy strictly applies to the collection and use of information by Infinity Group on this website. It does not apply to any of the information you may send to us by other means.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-6430715 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id={6430715}
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-95c4fd1"
                                    data-id="95c4fd1"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-9cf14cd elementor-widget elementor-widget-heading"
                                            data-id="9cf14cd"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <h5 className="elementor-heading-title elementor-size-default">
                                                Changes to Privacy Policy
                                                </h5>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-340630d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="340630d"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2d751b3"
                                    data-id="2d751b3"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-5a915c8 elementor-widget elementor-widget-heading"
                                            data-id="5a915c8"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <p className="elementor-heading-title elementor-size-default">
                                                Any alterations or modifications to this Privacy Policy will be communicated here on this page, so it is important to check back on occasion.
                                                </p>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-22d0774 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="22d0774"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4b82132"
                                    data-id="4b82132"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-9c2161e elementor-widget elementor-widget-heading"
                                            data-id="9c2161e"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <h5 className="elementor-heading-title elementor-size-default">
                                                Who is permitted to use this website?
                                                </h5>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f5067fd elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="f5067fd"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-82e3f04"
                                    data-id="82e3f04"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-4ad2047 elementor-widget elementor-widget-heading"
                                            data-id="4ad2047"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <p className="elementor-heading-title elementor-size-default">
                                                Adults only. This website, in no way whatsoever, has been intended, designed, or manipulated to attract the attention of users under the age of 21, depending on the state laws of the individual regarding the legal smoking age. We do not collect data which may be considered personally identifiable from any person known to be below the age of 21. We state clearly that this site is not intended for those under the legal smoking age, and furthermore, we instruct those individuals to not send us information.
                                                The information contained on this site is restricted to users in the United States. Regarding users in other countries, it is highly advisable not to disclose your personal information on this website. The data, which you transmit, is sent to a server in the United States. By sending personal information and data to this website, you are agreeing to be bound by the laws of the United States.
                                                </p>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-22d0774 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="22d0774"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4b82132"
                                    data-id="4b82132"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-9c2161e elementor-widget elementor-widget-heading"
                                            data-id="9c2161e"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <h5 className="elementor-heading-title elementor-size-default">
                                                Information collected from and about you
                                                </h5>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f5067fd elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="f5067fd"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-82e3f04"
                                    data-id="82e3f04"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-4ad2047 elementor-widget elementor-widget-heading"
                                            data-id="4ad2047"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <p className="elementor-heading-title elementor-size-default">
                                                The information we collect from you varies, depending on the ways you use our website, and not all browsing results in the collection of data, personal or otherwise. It is highly possible you may browse our site without submitting any personal information however there are instances where submitting information, such as your name, address, email address, credit or debit card information, and other personal information is required to use certain features or receive products and services.
Besides the information you choose to submit, our systems are programmed to collect certain types of data automatically on an anonymous level. This is done to give us insight on how our site is being used, as well as to help us understand how we may improve it in the future. This typically entails automatically gathering data such as any of the content you view on our website.
                                                </p>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                        
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-22d0774 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="22d0774"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4b82132"
                                    data-id="4b82132"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-9c2161e elementor-widget elementor-widget-heading"
                                            data-id="9c2161e"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <h5 className="elementor-heading-title elementor-size-default">
                                                How Your Information is used?
                                                </h5>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f5067fd elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="f5067fd"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-82e3f04"
                                    data-id="82e3f04"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-4ad2047 elementor-widget elementor-widget-heading"
                                            data-id="4ad2047"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <p className="elementor-heading-title elementor-size-default">
                                                When you provide personal information, it is used to customize your online experience on our website. Information you send to use, such as age, occupation, ZIP code, or other data may be used to offer personalized content, beneficial advertisements, products, and additional services that are most likely going to appeal to you based on the information given.
We use personal information to respond directly to requests you may make of us, as well as reference this information should we feel the need to better understand your needs and how we may be able to improve our services, products, and website. We may also use this personal information to contact you and/ or provide information we deem may be useful to you such as promotions. There is also a possibility we may enhance or merge this information with data we obtain from third parties for these same intended purposes. We may also send you promotional and/or administrative communications via the email address you have provided. You can unsubscribe from commercial e-mail messages, but you may not be able to unsubscribe from certain non-commercial, administrative communications.
                                                </p>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-22d0774 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="22d0774"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4b82132"
                                    data-id="4b82132"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-9c2161e elementor-widget elementor-widget-heading"
                                            data-id="9c2161e"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <h5 className="elementor-heading-title elementor-size-default">
                                                Access to Your Personal Information
                                                </h5>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f5067fd elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="f5067fd"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-82e3f04"
                                    data-id="82e3f04"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-4ad2047 elementor-widget elementor-widget-heading"
                                            data-id="4ad2047"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <p className="elementor-heading-title elementor-size-default">
                                                Infinity Group may use your personal information to reply to you as well as to send information to you. In no way does Infinity Group intend to disclose personal information to third parties except those explicitly stated in this Privacy Policy. It should be known that Infinity Group may provide aggregated information, which we receive from you, to third parties.
In the event that Infinity Group, or our assets are sold, transferred, or used as security, the personal information we have collected through our website may become transferred to third parties in connection with that transaction. Infinity Group may also give access to, or provide personal information to any of our business affiliates and/ or business partners. There is a possibility that we may share your personal information with additional companies whose products and services may be of interest to you.
In the event that Infinity Group is requested by law enforcement officials and/or judicial authorities to provide personal information on individuals who have used our system, Infinity Group may, without your consent, provide any of this information. In matters that involve claims of public or personal safety or litigation where this data is considered pertinent, Infinity Group may use or disclose your personal information without your consent or court process.
                                                </p>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-22d0774 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="22d0774"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4b82132"
                                    data-id="4b82132"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-9c2161e elementor-widget elementor-widget-heading"
                                            data-id="9c2161e"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <h5 className="elementor-heading-title elementor-size-default">
                                                Will Your Personal Information Be Secure?
                                                </h5>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f5067fd elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="f5067fd"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-82e3f04"
                                    data-id="82e3f04"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-4ad2047 elementor-widget elementor-widget-heading"
                                            data-id="4ad2047"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <p className="elementor-heading-title elementor-size-default">
                                                Infinity Group recognizes and understands your concerns on the subject of safety and security regarding your personal information. We strive to employ reasonable measures to protect your information as well as the overall security of our website. We also request that any third parties we are affiliated with, whom we may transfer your information to take the same or comparable steps to protect your information and security. With that said, it should be noted that even with such advanced technology, no website is 100% secure.
                                                </p>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>


                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-22d0774 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="22d0774"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4b82132"
                                    data-id="4b82132"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-9c2161e elementor-widget elementor-widget-heading"
                                            data-id="9c2161e"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <h5 className="elementor-heading-title elementor-size-default">
                                                California Privacy Rights
                                                </h5>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f5067fd elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="f5067fd"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-82e3f04"
                                    data-id="82e3f04"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-4ad2047 elementor-widget elementor-widget-heading"
                                            data-id="4ad2047"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <p className="elementor-heading-title elementor-size-default">
                                                Under California Law, California residents have the right to receive, once per year, information about third parties with whom we have shared information about you for their marketing purposes during the previous calendar year, and a description of the categories of personal information shared. To make such a request, please send a request via e-mail to Infinity Group@gmail.com with the phrase "California Privacy Request" in the subject line, and provide us with your name, address, and e-mail address. We will respond to you within thirty days of receiving such a request.
                                                </p>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>


                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-22d0774 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="22d0774"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4b82132"
                                    data-id="4b82132"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-9c2161e elementor-widget elementor-widget-heading"
                                            data-id="9c2161e"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <h5 className="elementor-heading-title elementor-size-default">
                                                Right to Access of Personal Information
                                                </h5>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f5067fd elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="f5067fd"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-82e3f04"
                                    data-id="82e3f04"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-4ad2047 elementor-widget elementor-widget-heading"
                                            data-id="4ad2047"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <p className="elementor-heading-title elementor-size-default">
                                                We only store data that is shared by the customer during registration and while placing orders. Registered users can access their data stored by logging into their account. To request a download of the stored data, users can submit a support ticket.
                                                </p>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        


                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-22d0774 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="22d0774"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4b82132"
                                    data-id="4b82132"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-9c2161e elementor-widget elementor-widget-heading"
                                            data-id="9c2161e"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <h5 className="elementor-heading-title elementor-size-default">
                                                Questions Regarding This Privacy Policy
                                                </h5>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f5067fd elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="f5067fd"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-82e3f04"
                                    data-id="82e3f04"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-4ad2047 elementor-widget elementor-widget-heading"
                                            data-id="4ad2047"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <p className="elementor-heading-title elementor-size-default">
                                                Should you have any questions, concerns, or comments regarding our privacy practices, please do not hesitate to e-mail us at Infinity Group@gmail.com
                                                </p>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                    </div>
                </div>
                {/* homepage-content */}
            </div>
            {/* site-content */}
        </main>

    );
}

export default PrivacyPolicy;