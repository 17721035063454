import axios from "axios";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify";
import { register } from "../../services/AuthService";
import { getAllStates } from "../../services/StateService";
import { AppContext } from "../../utils/AppProvider";

const Register = () => {
    const [states, setStates] = useState([]);
    const [state, setState] = useState({
        name:'',
        email:'',
        company_name:'',
        address:'',
        city:'',
        state_id:'',
        tax_id:'',
        password:''
    });
    const { setIsAuthenticated } = useContext(AppContext);

    const navigate = useNavigate();

    useEffect(()=>{
        getStates();
    },[]);

    const getStates = async() =>{
        await getAllStates().then((response) =>{
            setStates(response.data);
        }).catch((error)=>{

        });
    }

    const handleInputChange =(e)=>{
        let {name, value} = e.target;
        
        setState({...state, [name]:value});
    }   

    const handleSubmit = async () =>{
        if(validateInput()){
            await register(state).then((response)=>{
                localStorage.setItem('is_authenticated', 1);
                localStorage.setItem('token', response.data.data.token);
                setIsAuthenticated(1);
                toast('Customer Registered Successfully',{type: 'success'})

                navigate("/");
            }).catch((error) =>{
                if(error.response.status == 422)
                    toast(error.response.data.error,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
            });
        }
    }

    const validateInput = () => {
        if(state.name == ''){
            toast("Please enter name.",{
                type:'error'
            });
            return false
        }
        if(state.email == ''){
            toast("Please enter email.",{
                type:'error' 
            });
            return false
        }
        if(state.password == ''){
            toast("Please enter password.",{
                type:'error'
            });
            return false
        }
        
        return true;
    }

    return (
        <main id="main" className="site-primary">
            <div className="site-content">
                <div className="homepage-content">
                    <div className="shop-content my-account-page">
                        <div className="container">
                            <nav className="woocommerce-breadcrumb"><ul><li><a href="#">Home</a></li><li>My account</li></ul></nav>
                            <div className="woocommerce"><div className="woocommerce-notices-wrapper"></div>
                                <div className="site-login">
                                    <div className="site-login-container">
                                        <div className="site-login-overflow">
                                            <ul className="login-page-tab">
                                                <li> <Link to="/login"> Login </Link> </li>

                                                <li><a href="#" className="active">Register</a></li>
                                            </ul>
                                            <div id="customer_login" className="login-form-container">
                                                <div className="register-form">
                                                    <form method="post" className="woocommerce-form woocommerce-form-register register">
                                                        <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                                            <label htmlFor="username"> Name &nbsp;<span className="required">*</span></label>
                                                            <input onChange={(e) => handleInputChange(e) } type="text" className="woocommerce-Input woocommerce-Input--text input-text" name='name' id="username" autoComplete="username" value={state.name} />
                                                        </p>

                                                        <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                                            <label htmlFor="reg_email">Email address&nbsp;<span className="required">*</span></label>
                                                            <input onChange={(e) => handleInputChange(e) } type="email" className="woocommerce-Input woocommerce-Input--text input-text" name="email" id="reg_email" autoComplete="email" value={state.email} />
                                                        </p>

                                                        <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                                            <label htmlFor="reg_password">Password&nbsp;<span className="required">*</span></label>
                                                            <span className="password-input"><input onChange={(e) => handleInputChange(e) } value={state.password} type="password" className="woocommerce-Input woocommerce-Input--text input-text" name="password" id="reg_password" autoComplete="new-password" /><span className="show-password-input"></span></span>
                                                        </p>
                                                        
                                                        <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                                            <label htmlFor="company_name">Company Name </label>
                                                            <input onChange={(e) => handleInputChange(e) } type="text" className="woocommerce-Input woocommerce-Input--text input-text" name="company_name" id="company_name" value={state.company_name} />
                                                        </p>
                                                        
                                                        <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                                            <label htmlFor="address">Address </label>
                                                            <textarea rows={2} onChange={(e) => handleInputChange(e) } className="woocommerce-Input woocommerce-Input--text input-text" style={{height:'auto'}} name="address" id="address" value={state.address} ></textarea>
                                                        </p>
                                                        
                                                        <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                                            <label htmlFor="city">City </label>
                                                            <input onChange={(e) => handleInputChange(e) } type="text" className="woocommerce-Input woocommerce-Input--text input-text" name="city" id="city" value={state.city} />
                                                        </p>
                                                        
                                                        <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                                            <label htmlFor="state_id"> State </label>
                                                            <select name="state_id" value={state.state_id} onChange={(e)=> handleInputChange(e)} className="orderby filterSelect">
                                                                <option value="">Select State</option>
                                                                {
                                                                    states.map((state,index)=>{
                                                                        return <option value={state.id}> {state.name} </option>
                                                                    })
                                                                }                                                                
                                                            </select>
                                                        </p>

                                                        <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                                            <label htmlFor="tax_id"> Tax ID </label>
                                                            <input onChange={(e) => handleInputChange(e) } type="text" className="woocommerce-Input woocommerce-Input--text input-text" name="tax_id" id="tax_id" value={state.tax_id} />
                                                        </p>                                                        
                                                        
                                                        <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                                            <input value="" type="hidden" className="woocommerce-Input woocommerce-Input--text input-text" name="extra" id="extra" />
                                                        </p>


                                                        <div className="woocommerce-privacy-policy-text"><p>Your personal data will be used to support your experience throughout this website, to manage access to your account, and htmlFor other purposes described in our <a href="#/privacy-policy/" className="woocommerce-privacy-policy-link" target="_blank">privacy policy</a>.</p>
                                                        </div>
                                                        <p className="woocommerce-form-row form-row">
                                                            <button type="button" onClick={()=> handleSubmit()} className="woocommerce-Button woocommerce-button button woocommerce-form-register__submit" name="register" value="Register">Register</button>
                                                        </p>


                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Register;