import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const getCartItems = async() => {
    let token =await localStorage.getItem('token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }

    return new Promise(function (resolve, reject) {
        axios.get('/customer/cart-items',{headers:headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const getCartItemQty = async() => {
    let token =await localStorage.getItem('token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }

    return new Promise(function (resolve, reject) {
        axios.get('/customer/cart-items-qty',{headers:headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const removeItemFromCart = async(cart_id) => {
    let token =await localStorage.getItem('token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }

    return new Promise(function (resolve, reject) {
        axios.get(`/customer/remove-item-from-cart?item_id=${cart_id}`,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const increaseDecreaseCartItemQty = async(cart_id, increase = true) => {
    let token =await localStorage.getItem('token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }

    return new Promise(function (resolve, reject) {
        axios.get(`/customer/increase-decrease-cart-item-qty?cart_id=${cart_id}&increase=${increase}`,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}


export { getCartItems, removeItemFromCart, increaseDecreaseCartItemQty, getCartItemQty };