const HeaderMobileNavbar = () => {
    return (
        <nav className="header-mobile-nav">
            <div className="mobile-nav-wrapper">
                <ul>
                    <li className="menu-item">
                        <a href="#/shop/" className="store">
                            <i className="klbth-icon-store"></i>
                            <span>Store</span>
                        </a>
                    </li>


                    <li className="menu-item">
                        <a href="#/home-3/#" className="search">
                            <i className="klbth-icon-search"></i>
                            <span>Search</span>
                        </a>
                    </li>

                    <li className="menu-item">
                        <a href="#/wishlist/" className="wishlist">
                            <i className="klbth-icon-heart-1"></i>
                            <span>Wishlist</span>
                        </a>
                    </li>

                    <li className="menu-item">
                        <a href="#/my-account/" className="user">
                            <i className="klbth-icon-user"></i>
                            <span>Account</span>
                        </a>
                    </li>

                    <li className="menu-item">
                        <a href="#/home-3/#" className="categories">
                            <i className="klbth-icon-menu-thin"></i>
                            <span>Categories</span>
                        </a>
                    </li>

                </ul>
            </div>
        </nav>
    );
}

export default HeaderMobileNavbar;