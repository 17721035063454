
const Discount = () => {
    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-3d0b96d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="3d0b96d" data-element_type="section">
            <div className="elementor-container elementor-column-gap-extended">
                <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8947bd2"
                    data-id="8947bd2" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-0ae80da elementor-widget elementor-widget-bacola-banner-box3"
                            data-id="0ae80da" data-element_type="widget"
                            data-widget_type="bacola-banner-box3.default">
                            <div className="elementor-widget-container">
                                <div className="site-module module-banner image align-left align-center">
                                    <div className="module-body">
                                        <div className="banner-wrapper">
                                            <div className="banner-content">
                                                <div className="content-header">
                                                    <div className="discount-text color-success">Weekend Discount 40%</div>
                                                </div>
                                                <div className="content-main">
                                                    <h3 className="entry-title color-text-light">Cookie and Ice Cream</h3>
                                                    <div className="entry-text color-info-dark">Bacola Weekend Discount</div>
                                                </div><a
                                                    href="/product/112/blue-diamond-almonds-lightly-salted/"
                                                    className="button button-primary rounded xsmall">Shop Now</a>
                                            </div>
                                            <div className="banner-thumbnail"><img src="/images/bacola-banner-11.jpg"
                                                alt="banner" /></div><a
                                                    href="/product/112/blue-diamond-almonds-lightly-salted/"
                                                    className="overlay-link"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-788ecaf"
                    data-id="788ecaf" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-26fb205 elementor-widget elementor-widget-bacola-banner-box3"
                            data-id="26fb205" data-element_type="widget"
                            data-widget_type="bacola-banner-box3.default">
                            <div className="elementor-widget-container">
                                <div className="site-module module-banner image align-left align-center">
                                    <div className="module-body">
                                        <div className="banner-wrapper">
                                            <div className="banner-content">
                                                <div className="content-header">
                                                    <div className="discount-text color-success">Weekend Discount 30%</div>
                                                </div>
                                                <div className="content-main">
                                                    <h3 className="entry-title color-text-light">Cookie and Ice Cream</h3>
                                                    <div className="entry-text color-info-dark">Bacola Weekend Discount</div>
                                                </div><a href="/shop/?filter_cat=31,32"
                                                    className="button button-danger rounded xsmall">Shop Now</a>
                                            </div>
                                            <div className="banner-thumbnail"><img src="/images/bacola-banner-12.jpg"
                                                alt="banner" /></div><a
                                                    href="/shop/?filter_cat=31,32"
                                                    className="overlay-link"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Discount;