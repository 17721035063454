import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const retriveCartAndCustomer = async() => {
    let token = localStorage.getItem('token');
    let headers = {
        'Authorization': `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get('/customer/cart-customer',{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}


const createOrder = async(order) => {
    let token = localStorage.getItem('token');
    let headers = {
        'Authorization': `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.post('/customer/create-order',{...order},{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const retriveOrders = async() => {
    let token = localStorage.getItem('token');
    let headers = {
        'Authorization': `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get('/customer/retrive-orders',{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const confirmOrder = async(sales_id) => {
    let token = localStorage.getItem('token');
    let headers = {
        'Authorization': `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.post('/agent/delivery/customer-order-confirm',{sales_id:sales_id},{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

export { retriveCartAndCustomer, createOrder, retriveOrders,confirmOrder };