import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const login = (data) => {
    return new Promise(function (resolve, reject) {
        axios.post('/customer/login', { ...data })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const register = (data) => {
    return new Promise(function (resolve, reject) {
        axios.post('/customer/register', { ...data })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}


const lostPassword = (email, token) => {
    return new Promise(function (resolve, reject) {
        axios.post('/customer/lost-password', { email: email, token: token })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const resetPassword = (data) => {
    return new Promise(function (resolve, reject) {
        axios.post('/customer/reset-password', { ...data })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const logout = async() => {
    let token =await localStorage.getItem('token')
    let headers ={
        
        'Authorization' : `Bearer ${token}`
    }

    return new Promise(function (resolve, reject) {
        axios.get('/customer/logout',{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

export { login, register, logout, lostPassword, resetPassword };